import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

// Types
import { ipregistryResponce } from '@app/types';

// Consts
import { LOCALE } from '@app/common/constants';

// Services
import { StorageService } from '@services/storage.service';
import { SettingService } from './setting.service';

@Injectable({ providedIn: 'root' })
export class GetLocaleService {
  public isUSUser: boolean;

  public isIframeEntry: boolean;

  private _isUsLocale: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public isUsLocale$ = this._isUsLocale.asObservable();

  constructor(
    @Inject(WINDOW) private window: any,
    @Inject(PLATFORM_ID) private platformId: any,
    private http: HttpClient,
    private settingsService: SettingService,
    private storageService: StorageService
  ) {}

  /* Enable for USA locale */

  /*isUsaLocale(): Observable<any> {
    const isDefinedLocale: boolean =
      this._isIframeEntry() ||
      !!this.storageService.getStorageItem('locale')?.length ||
      this.isLoggedUsaUser() ||
      typeof this.isUSUser === 'boolean';

    const isSavedLocaleUS: boolean =
      this._isIframeEntry() ||
      this.storageService.getStorageItem('locale') === LOCALE.US ||
      this.isLoggedUsaUser() ||
      this.isUSUser === true;

    const observable: Observable<boolean> = isDefinedLocale
      ? of(isSavedLocaleUS)
      : this.isUsaFromIp();

    return observable.pipe(
      tap((isUS: boolean) => {
        this.isUSUser = isUS;

        this._isUsLocale.next(isUS);

        if (isUS) {
          this.storageService.setStorageItem('locale', LOCALE.US);
          this.settingsService.setLocale(LOCALE.US);
          console.info('US locale detected');

        } else {
          this.storageService.setStorageItem('locale', LOCALE.OTHER);
          this.settingsService.setLocale(LOCALE.OTHER);
          console.info('Non US locale detected');
        }
      })
    );
  }
  */

  /* Disable to allow USA locale */
  
  isUsaLocale(): Observable<any> {
    this.storageService.setStorageItem('locale', LOCALE.OTHER);
    this.settingsService.setLocale(LOCALE.OTHER);
    return of(false);
  }

  private redirectToIdealIx(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.window.location.assign(this.settingsService.idealIxUrl);
    }
  }

  private isLoggedUsaUser(): boolean {
    return this.storageService.user?.country === LOCALE.US;
  }

  private _isIframeEntry(): boolean {
    this.isIframeEntry = Boolean(
      new URLSearchParams(window.location.search).get('idealidx')
    );
    return this.isIframeEntry;
  }

  setIframeEntry(): void {
    this.isIframeEntry = true;
  }

  getCountryCodeFromIp(): Observable<string> {
    return this.http
      .get(
        `https://api.ipregistry.co/?key=${this.settingsService.ipregistryKey}&fields=location.country.code`
      )
      .pipe(map((res: ipregistryResponce) => res.location.country.code));
  }

  isUsaFromIp(): Observable<boolean> {
    return this.getCountryCodeFromIp().pipe(
      map((countryCode: string) => countryCode === LOCALE.US)
    );
  }
}
