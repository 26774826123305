import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from '../../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { StorageService } from '@services/storage.service';

function getWindow(): any {
  return window;
}

interface IGoogleData {
  slug: string;
  share: string | number;
  transactionId: string;
  isAlbum: boolean;
  amount: number;
}

interface IDataUserPayment {
  quantity: number;
  originPrice: number;
  price: number;
}

/** This googleTag needs to be deleted 28.03.2023 **/

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  private window;
  isEnabled: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private storage: StorageService
  ) {
    this.isEnabled = environment.gaEnabled;
    if (this.isEnabled && isPlatformBrowser(this.platformId)) {
      this.window = getWindow();
    }
  }

  private pushDataLayer(obj) {
    if (this.isEnabled && isPlatformBrowser(this.platformId)) {
      this.window.dataLayer.push({ ecommerce: null });
      if (obj && this.isEnabled) {
        this.window.dataLayer.push(obj);
      }
    }
  }

  private checkPriceForShare(data): { price: number } | {} {
    if (data?.priceForShare?.eur) {
      return { price: data?.priceForShare.eur / 10 };
    } else {
      return {};
    }
  }

  logCheckout(data, dataUserPayment: IDataUserPayment) {
    let quantity = dataUserPayment.quantity * 10;
    let pricePerShare = data.priceForShare.eur / 10;
    let discount = data.saleAmount
      ? (pricePerShare * data.saleAmount) / 100
      : 0;

    let value = pricePerShare * quantity - discount;

    const DATA_LAYER = {
      event: 'begin_checkout',
      ecommerce: {
        currency: 'EUR',
        value: parseFloat(value.toFixed(2)),
        items: [
          {
            item_id: data._id,
            item_name: data.artist.name,
            affiliation: data.song.label,
            discount: parseFloat(discount.toFixed(2)),
            item_brand: data.song.name,
            item_category: 'Music Shares',
            item_category2: 'Single',
            item_category3: 'Upcoming Release',
            item_list_id: 'ALL',
            item_list_name: 'ALL',
            price: pricePerShare,
            quantity: quantity,
          },
        ],
      },
    };
    this.pushDataLayer(DATA_LAYER);
  }

  logViewItem(data) {
    let price = data.priceForShare.eur / 10;
    let discount = data.saleAmount ? (data.saleAmount / 100) * price : 0;
    let value = price - discount;

    const DATA_LAYER = {
      event: 'view_item',
      ecommerce: {
        currency: 'EUR',
        value: parseFloat(value.toFixed(2)),
        items: [
          {
            item_id: data._id,
            item_name: data.artist.name,
            affiliation: data.song.label,
            discount: parseFloat(discount.toFixed(2)),
            item_brand: data.song.name,
            item_category: 'Music Shares',
            item_category2: 'Single',
            item_category3: 'Upcoming Release',
            item_list_id: 'ALL',
            item_list_name: 'ALL',
            price: price,
            quantity: 1,
          },
        ],
      },
    };
    this.pushDataLayer(DATA_LAYER);
  }

  logSignIn() {
    const user = this.storage.user;
    const DATA_LAYER = {
      event: 'sign_up',
      method: 'email',
      user_id: user._id,
    };

    this.pushDataLayer(DATA_LAYER);
  }

  logSelectItem(data) {
    const DATA_LAYER = {
      event: 'select_item',
      ecommerce: {
        item_list_id: 'ALL',
        item_list_name: 'ALL',
        items: [
          {
            item_id: data._id,
            item_name: data.artist.name,
            affiliation: data.song.label,
            discount: 0,
            item_brand: data.song.name,
            item_category: 'Music Shares',
            item_category2: 'Single',
            item_category3: 'Upcoming Release',
            item_list_id: 'ALL',
            item_list_name: 'ALL',
            quantity: 1,
            ...this.checkPriceForShare(data),
          },
        ],
      },
    };
    this.pushDataLayer(DATA_LAYER);
  }

  logList(data) {
    let projects = data.map((resp) => {
      return {
        item_id: resp._id,
        item_name: resp.artist ? resp.artist.name : '',
        affiliation: resp.song.label,
        discount: 0,
        item_brand: resp.song.name,
        item_category: 'Music Shares',
        item_category2: 'Single',
        item_category3: 'Upcoming Release',
        item_list_id: 'ALL',
        item_list_name: 'ALL',
        price: resp?.priceForShare ? resp?.priceForShare?.eur / 10 : '',
        quantity: 1,
      };
    });

    const DATA_LAYER = {
      event: 'view_item_list',
      ecommerce: {
        item_list_id: 'ALL',
        item_list_name: 'ALL',
        items: projects,
      },
    };
    this.pushDataLayer(DATA_LAYER);
  }

  logPurchase(data) {
    let DATA_LAYER = {
      event: 'purchase',
      ecommerce: {
        transaction_id: data.transactionId,
        currency: 'EUR',
        value: parseFloat(data.value.toFixed(2)),
        payment_type: data.paymentMethod,
        items: [
          {
            item_id: data.item_id,
            item_name: data.item_category,
            affiliation: data.affiliation,
            discount: parseFloat(data.discount.toFixed(2)),
            item_brand: data.item_name,
            item_category: 'Music Shares',
            item_category2: 'Single',
            item_category3: 'Upcoming Release',
            item_list_id: 'ALL',
            item_list_name: 'ALL',
            price: data.price,
            quantity: data.quantity,
          },
        ],
      },
    };
    if (data.salePercent) {
      DATA_LAYER.ecommerce.items[0][
        'promotion_name'
      ] = `Sale ${data.salePercent}%`;
    }
    if (data.voucherAmount) {
      DATA_LAYER.ecommerce['coupon'] = 'Voucher';
      DATA_LAYER.ecommerce.items[0]['coupon'] = 'Voucher';
    }

    this.pushDataLayer(DATA_LAYER);
  }
}
