import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';

// Types
import { LocaleName } from '@app/types';

// Consts
import { LOCALE } from 'app/common/constants';
@Injectable({
  providedIn: 'root',
})
export class SettingService {
  private scrollParams = new Subject<any>();
  private $headerStickParams = new Subject<any>();
  private _locale: LocaleName = null;

  get nftRedirectSlug(): string {
    return environment.nftRedirectSlug;
  }
  get microsoftViewFileAPI(): string {
    return environment.microsoftViewFileAPI;
  }
  get ourLabelSlug(): string {
    return environment.ourLabelSlug;
  }
  get appleClientId(): string {
    return environment.appleClientId;
  }
  get appleRedirectUrl(): string {
    return environment.appleRedirectURL;
  }
  get magicKey(): string {
    return environment.magicKey;
  }
  get apiUrl(): string {
    return environment.apiUrl;
  }
  get imgUrl(): string {
    return environment.imgUrl;
  }
  get lock(): any {
    return environment.lock;
  }

  get facebookId(): string {
    return environment.facebookId;
  }

  get gaEnabled(): boolean {
    return environment.gaEnabled;
  }
  get fbEnabled(): boolean {
    return environment.fbEnabled;
  }

  get ipregistryKey(): string {
    return environment.ipregistryKey;
  }

  get idealIxUrl(): string {
    return environment.idealIxUrl;
  }

  get idealixParentWindowDomain(): string {
    return environment.idealixParentWindowDomain;
  }

  get locale(): LocaleName {
    return this._locale;
  }

  get curency(): string {
    return this.locale === LOCALE.US ? 'USD' : 'EUR';
  }

  get currencySymol(): string {
    return this.locale === LOCALE.US ? '$' : '€';
  }

  setLocale(locale: LocaleName): void {
    this._locale = locale;
  }

  validationIntegerAmount(amount): boolean {
    return (
      !amount ||
      isNaN(amount) ||
      Number(amount) <= 0 ||
      !Number.isInteger(Number(amount))
    );
  }

  setScroll(param) {
    this.scrollParams.next(param);
  }

  getScroll() {
    return this.scrollParams.asObservable();
  }
  setHeaderStick(param) {
    this.$headerStickParams.next(param);
  }

  getHeaderStick() {
    return this.$headerStickParams.asObservable();
  }
}
