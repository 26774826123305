import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { delay, filter, tap } from 'rxjs/operators';
import { ComponentPortal } from '@angular/cdk/portal';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isPlatformBrowser } from '@angular/common';
import { WINDOW } from '@ng-web-apis/common';

// Interfaces
import {
  IdxAuthData,
  IdxToGrsUserPayload,
  IdxUser,
  IdxUserData,
} from './interfaces/social-user.interface';

// Services
import { ModalService } from '@services/modal.service';
import { DictionaryService, SettingService } from './services';
import { GetLocaleService } from './services/get-locale.service';
import { AuthenticationService } from './services/authentication.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(WINDOW) private window: any,
    private modalService: ModalService,
    private getLocaleService: GetLocaleService,
    private settingsService: SettingService,
    private authService: AuthenticationService,
    private dictionaryService: DictionaryService
  ) {}

  currentModal$: Observable<ComponentPortal<any>> =
    this.modalService.modal$.pipe(untilDestroyed(this), delay(0));

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.getLocaleService.isUsaLocale().subscribe((isUsa: boolean) => {
        // if (isUsa && this.getLocaleService.isIframeEntry) {
        if (isUsa) {
          this.subscribeToParentWindow();
        }
      });
    }
  }

  private subscribeToParentWindow(): void {
    fromEvent(this.window, 'message')
      .pipe(
        filter(
          (event: MessageEvent) =>
            event.origin === this.settingsService.idealixParentWindowDomain
        )
      )
      .subscribe((event: MessageEvent) => {
        this.getLocaleService.setIframeEntry();
        const data: IdxAuthData = event.data;
        const { signature, payload } = data;

        const userData: IdxUser = JSON.parse(payload).user_data;

        if (userData.user_email) {
          const userPayload: IdxToGrsUserPayload = {
            user_email: userData.user_email,
            user_name: userData.user_name,
            user_country: '',
            user_id: `${userData.user_id}`,
            user_state: userData.user_state || 'Alabama',
            signature,
            payload,
          };

          this.dictionaryService.getCountries().subscribe(() => {
            userPayload.user_country =
              this.dictionaryService.getCountryCode(userData.user_country) ||
              'US';

            this.authService.idxLogin(userPayload).subscribe();
          });
        }
      });
  }
}
