import {HttpParams} from '@angular/common/http';

export class RequestModel {
  limit: number;
  offset: number;
  page: number;
  filter: boolean;
  dateFrom: any;
  dateTo: any;
  id: string;
  typeSong: any;
  type: string;
  genres: string;
  artist: string;
  country: string;
  revenues: string;
  filters: string;
  status: string;
  token: string;
  sorting: any;
  kyc: boolean;
  wallet: string;
  investment: boolean;
  nft: boolean;
  nickname: boolean;
  contactData: boolean;
  mangopayEn: boolean;
  paging: {
    offset: number,
    limit: number,
    page: number
  };
  constructor(init: any) {
    Object.assign(this, init);
  }

  getApiModel(): HttpParams {
    let result = new HttpParams();
    if (this.limit || this.limit === 0) {
      result = result.set('limit', this.limit.toString());
    }
    if (this.offset || this.offset === 0) {
      result = result.set('offset', this.offset.toString());
    }
    if (this.nickname) {
      result = result.set('nickname', this.nickname.toString());
    }
    if (this.paging && (this.paging.offset || this.paging.offset === 0)) {
      result = result.set('offset', this.paging.offset.toString());
    }
    if (this.paging && (this.paging.page || this.paging.page === 0)) {
      result = result.set('page', this.paging.page.toString());
    }
    if (this.paging && this.paging.limit) {
      result = result.set('limit', this.paging.limit.toString());
    }
    if (this.kyc) {
      result = result.set('kyc', this.kyc.toString());
    }
    if (this.mangopayEn) {
      result = result.set('mangopayEn', this.mangopayEn.toString());
    }
    if (this.id) {
      result = result.set('id', this.id.toString());
    }
    if (this.token) {
      result = result.set('token', this.token.toString());
    }
    if (this.contactData) {
      result = result.set('contactData', this.contactData.toString());
    }
    if (this.wallet) {
      result = result.set('wallet', this.wallet.toString());
    }
    if (this.nft) {
      result = result.set('nft', this.nft.toString());
    }
    if (this.investment) {
      result = result.set('investment', this.investment.toString());
    }
    if (this.revenues) {
      result = result.set('revenues', this.revenues.toString());
    }
    if (this.page) {
      result = result.set('page', this.page.toString());
    }
    if (this.filters) {
      result = result.set('filters', this.filters.toString());
    }
    if (this.filter) {
      result = result.set('filter', this.filter.toString());
    }
    if (this.dateFrom) {
      result = result.set('dateFrom', this.dateFrom.toString());
    }
    if (this.status) {
      result = result.set('status', this.status.toString());
    }
    if (this.artist) {
      result = result.set('artist', this.artist.toString());
    }
    if (this.dateTo) {
      result = result.set('dateTo', this.dateTo.toString());
    }
    if (this.typeSong) {
      result = result.set('typeSong', this.typeSong.toString());
    }
    if (this.sorting) {
      if (this.sorting.sortHow) {
        result = result.set('sort-how', this.sorting.sortHow.toString());
      }
      if (this.sorting.sortBy) {
        result = result.set('sort-by', this.sorting.sortBy.toString());
      }
    }
    if (this.country) {
      result = result.set('country', this.country.toString());
    }
    if (this.genres) {
      result = result.set('genres', this.genres.toString());
    }

    if (this.type) {
      result = result.set('type', this.type.toString());
    }
    return result;
  }

}
