import { UserModel } from './user.model';
import { IProjectNFTSong } from '@app/interfaces/nft.interface';

export class TokenNFTmodel {
  imgUrl: string;

  constructor(props?: string) {
    this.imgUrl = props;
  }

  artist: UserModel;
  backgroundImage: string;
  cardColor: string;
  isAlbum?: boolean;
  isCollectible: boolean = false;
  projectTitle: string;
  releaseCreated: boolean;
  royalties: string;
  royaltiesFromMaster?: boolean;
  royaltiesFromPublishingRights?: boolean;
  slug: string;
  song: IProjectNFTSong;
  songs?: Array<IProjectNFTSong>;

  setFromApiModel(apiModel: any, index?: number): this {
    Object.assign(this, apiModel);

    this.backgroundImage = `${this.imgUrl}/${apiModel.backgroundImage}`;
    this.royalties = this.formatRoyalties(apiModel);

    // TEMP CHANGE 
    if (this.song?.nft?.imageURL) {
      this.song.nft.imageURL = this.backgroundImage;
    }


    this.patchUrls(this.song);

    if (this.songs?.length) {
      this.songs.forEach((song: IProjectNFTSong, index: number) => {
        song.orderId = index + 1;

        if (song?.nft?.imageURL) {
          song.nft.imageURL = this.backgroundImage;
        }

        this.patchUrls(song);
      });
    }

    return this;
  }

  private patchUrls(song: IProjectNFTSong) {
    // TEMP CHANGE 
    // song.nft.imageGIFURL = song.nft?.imageGIFURL
    //   ? `${this.imgUrl}/${song.nft.imageGIFURL}`
    //   : null;

    song.nft.videoURL = song.nft?.videoURL
      ? `${this.imgUrl}/${song.nft.videoURL}`
      : null;

    // TEMP CHANGE 
    //song.nft.imageURL = `${this.imgUrl}/${song.nft.imageURL}`;
  }

  private formatRoyalties(apiModel: any): string {
    const royalties = [];
    if (apiModel.royaltiesFromMaster) {
      royalties.push('Master');
    }
    if (apiModel.royaltiesFromPublishingRights) {
      royalties.push('Publishing');
    }
    return royalties.join(' & ') + ' Rights';
  }
}
