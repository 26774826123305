import { Component, OnInit } from '@angular/core';
import { BankAccountModel } from '@app/models';

// Services
import { ProfileService } from '@app/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'bank-account',
  templateUrl: './bank-account.component.html',
  styleUrls: ['bank-account.component.scss'],
})
export class BankAccountComponent implements OnInit {

accounts: Array<BankAccountModel | {} > = null;

isAllowedToAddAccount: boolean = false;

country: string = this.profileService.syncGetUser().country;

showAddButton: boolean = false;

  constructor(private profileService: ProfileService) {}

  ngOnInit(): void {
    this.profileService.getBankAccount().subscribe((responce) => {
       this.isAllowedToAddAccount = responce.mangopay;        
    });

    this.profileService.bankAccounts$.pipe(untilDestroyed(this)).subscribe(
        (accounts: BankAccountModel[])=> {
            if(!accounts?.length) {
                this.accounts = [{}];
            }else {
                this.accounts = accounts;
                this.showAddButton = true;
            }
        }
    )
  }
  addBlankAccount():void {
    this.accounts = [...this.accounts, ...[{}]];
    this.showAddButton = false;
  }
}
