import {AfterViewInit, Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {ProjectsModel, RequestModel} from '../../models';
import {AppToastrService, ProfileService, SettingService} from 'app/services';
import {ActivatedRoute} from '@angular/router';
import {SongPreviewComponent} from '../../components/song-preview/song-preview.component';
import {SongPlayerComponent} from '../../components/song-player/song-player.component';
import {isPlatformBrowser} from '@angular/common';
import {Subscription} from 'rxjs';
import {_getSpotifyFollowers, _getTwitterFollowers, getRoyalties} from '../../common/helpers';
import {SeoService} from '../../services/seo.service';
import {CookieService} from 'ngx-cookie-service';
import {VideoPlayerComponent} from 'app/components/video-player/video-player.component';
import {CardProjectComponent} from 'app/components/cards/card-project/card-project.component';
import {StorageService} from '../../services/storage.service';
import {ProjectsService} from '../../services/projects.service';
import {GoogleAnalyticsService} from "@services/google-analytics.service";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-project',
  templateUrl: './project.html',
})

export class ProjectPageComponent implements OnInit, OnDestroy, AfterViewInit {
  project: ProjectsModel;
  slug: string;
  playAudio: boolean;
  isLoaded: boolean;
  royaltyPreview: string;
  spotifyId: any;
  stopPlaying: any;
  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '5%',
    variableWidth: false,
    dots: false,
    nextArrow: '<div class=\'nav-btn next-slide\'><span class="lnr lnr-chevron-left"></span></div>',
    prevArrow: '<div class=\'nav-btn prev-slide\'><span class="lnr lnr-chevron-right"></span></div>',
  };
  params: any;
  user: any;
  showReadMore: boolean;
  showDetail: boolean;
  showYouGet: boolean;
  showAboutArtist: boolean;
  showDescription: boolean;
  object: any;
  newsLetterNotification: boolean;
  doubleTab: any;
  host: any;
  mobile: boolean;
  projectSubscription: Subscription;
  youtubeFollowersSub: Subscription;
  video: HTMLVideoElement;
  enableVideoPlayer: boolean;
  cardProject: any;
  state: any;
  index: any;
  projectDesctription: any;
  @ViewChild(SongPlayerComponent, {static: false}) songPlayer: SongPlayerComponent;
  @ViewChild(SongPreviewComponent, {static: false}) songPreview: SongPreviewComponent;
  @ViewChild(VideoPlayerComponent, {static: false}) videoPlayer: VideoPlayerComponent;
  @ViewChild(CardProjectComponent) card: CardProjectComponent;

  @HostListener('window:resize') windowResize() {
    if (isPlatformBrowser(this.platformId)) {
      this.mobile = window.innerWidth < 1379;
    }
  }

  constructor(private storage: StorageService,
              private route: ActivatedRoute,
              @Inject(PLATFORM_ID) private platformId: any,
              private seoService: SeoService,
              private cookieService: CookieService,
              private toastr: AppToastrService,
              private googleAnalytics: GoogleAnalyticsService,
              private profileService: ProfileService,
              private settingService: SettingService,
              private projectService: ProjectsService,
              private sanitizer: DomSanitizer) {
    this.slug = this.route.snapshot.paramMap.get('slug');
    this.host = settingService.imgUrl;
  }

  ngOnInit(): void {
    this.enableVideoPlayer = false;
    this.getProjects();
    if (isPlatformBrowser(this.platformId)) {
      this.mobile = window.innerWidth < 1380;
      this.newsLetterNotification = this.cookieService.get('notification') ? JSON.parse(this.cookieService.get('notification')).visitorRegistered : null;
      if (this.storage.user) {
        const request = new RequestModel({});
        this.profileService.getUser(request).subscribe(resp => {
          this.user = resp;
        });
      }
    }
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth < 767) {
        setTimeout(() => {
          window.scrollBy({top: 400, left: 0, behavior: 'smooth'});
        }, 400);
      } else {
        window.scrollBy(0, 0);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.projectSubscription) {
      this.projectSubscription.unsubscribe();
    }
    if (this.youtubeFollowersSub) {
      this.youtubeFollowersSub.unsubscribe();
    }
  }

  getVideoObject(cardObject) {
    if (cardObject.status === 'play') {
      setTimeout(() => {
        this.cardProject = cardObject.card;
        this.video = cardObject.video;
        this.enableVideoPlayer = true;
        this.state = 'play';
        this.index = cardObject.index;
      }, 10);
    }
    if (cardObject.status === 'stop') {
      this.state = 'stop';
      this.cardProject = cardObject.card;
      this.video = cardObject.video;
      this.enableVideoPlayer = false;
      this.index = cardObject.index;
    }
  }

  stopPlayer(event) {
    this.card.currentPlayingVideo = this.video;
    this.card.pauseVideo(this.cardProject, this.index);
  }


  getProjectItem(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.songPreview) {
        this.songPreview.togglePlayer();
      }
      this.playAudio = true;
    }
  }

  onNotify(e: any, index?: number): void {
    if (isPlatformBrowser(this.platformId)) {
      this.video = (document.getElementById('project-component-video') as HTMLVideoElement);

    }
    if (this.video && this.video.paused === false) {
      this.video.pause();
    }
    if (e.type === 'playMusic') {
      this.stopPlaying = index;
      this.project.songs[index].audObj = e.data;
    }

    this.object = e.data;
  }

  parseCount(amount): number {
    if (amount > 1000000) {
      // tslint:disable-next-line: radix
      amount = parseInt((amount / 100000).toString()) / 10;
      amount = amount + 'M';
      return amount;
    } else {
      if (amount > 1000) {
        // tslint:disable-next-line: radix
        amount = parseInt((amount / 100).toString()) / 10;
        amount = amount + 'K';
        return amount;
      } else {
        return amount;
      }
    }
  }

  getYoutubeFollowers() {
    if (this.project.artist.social.youtube) {
      let link = this.project.artist.social.youtube;
      if (link && (link.includes('user') || link.includes('channel'))) {
        if (link.includes('user')) {
          link = link.replace('https://www.youtube.com/user/', '');
          link = link.replace('http://www.youtube.com/user/', '');
          link = link.replace('http://youtube.com/user/', '');
          link = link.replace('/', '');
          this.youtubeFollowersSub = this.projectService.getYouTubeUserId(link).subscribe(id => {
            if (id) {
              this.projectService.getYouTubeCount(this.slug, id).subscribe(response => {
                if (response) {
                  this.project.youtubeFollowers = this.parseCount(response.items[0].statistics.subscriberCount);
                  this.project.socialrow = true;
                }
              }, err => {
                this.isLoaded = true;
                this.toastr.showToastFromError(err);
              });
            }
          }, err => {
            this.isLoaded = true;
            this.toastr.showToastFromError(err);
          });
        } else {
          if (link.includes('channel')) {
            link = link.replace('https://www.youtube.com/channel/', '');
            link = link.replace('/', '');
            this.projectService.getYouTubeCount(this.slug, link).subscribe(response => {
              if (response) {
                this.project.youtubeFollowers = this.parseCount(response.items[0].statistics.subscriberCount);
                this.project.socialrow = true;
              }
            }, err => {
              this.isLoaded = true;
              this.toastr.showToastFromError(err);
            });
          }
        }
      }
    }
  }

  getRoyaltyPreview(project): void {
    if (isPlatformBrowser(this.platformId)) {
      this.royaltyPreview = getRoyalties(project, this.user);
    }
  }

  setSeo(project): void {
    this.seoService.updateCanonicalURL();
    if (project.seo.title) {
      this.seoService.updateTitle(project.seo.title);
      this.seoService.updateMetaTitle(project.seo.title);
    }
    if (project.seo.keywords) {
      this.seoService.updateKeywords(project.seo.keywords);
    }

    if (project.seo.description) {
      this.seoService.updateDescription(project.seo.description);
    }
  }

  getProjects(): void {
    const slug = !this.slug && this.doubleTab ? this.doubleTab : this.slug;
    this.projectSubscription = this.projectService.getProject(slug).subscribe(resp => {
      if (resp) {
        this.googleAnalytics.logViewItem(resp);
        this.projectDesctription = this.sanitizer.bypassSecurityTrustHtml(resp['myProjectDescription']);
        this.project = resp;

        if (this.project.seo) {
          this.setSeo(this.project);
        }
        if (this.project && this.project.songs) {
          this.project.songs.forEach((item, index) => {
            item.index = index;
          });
        }
        this.isLoaded = true;
        this.getRoyaltyPreview(resp);
       // this.getYoutubeFollowers();
        _getSpotifyFollowers(this.project);
        _getTwitterFollowers(this.project);

      }

    }, err => {
      this.isLoaded = true;
      this.toastr.showToastFromError(err);
    });
  }
}
