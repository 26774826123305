import 'reflect-metadata';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthenticationService } from '../services/authentication.service';

import { LoginPageComponent, RegisterComponent } from './index';
import { MainPageComponent } from './main-page/main-page.component';
import { ComponentsModule } from '../components/components.module';
import {
  AppToastrService,
  DictionaryService,
  ImageService,
  PledgeService,
} from '../services';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { PipesModule } from '../pipes/pipes.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ToastrModule } from 'ngx-toastr';
import { RouterModule } from '@angular/router';
import { ProfileService } from '../services/profile.service';
import { SocialLoginModule } from '@abacritt/angularx-social-login';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AuthenticationInterceptor } from '../common';
import { LandingService } from '../services/landing.service';
import { RoutingService } from '../services/routing.service';
import { GlobalObjectService } from '../services/global-object.service';
import { VideoService } from '../services/video.service';
import { AudioService } from '../services/audio.service';
import { AudioPlayerService } from '../services/audioPlayer.service';
import { SharedModule } from '../shared.module';
import { VerifyPageComponent } from './verify/verify.component';
import { CookieService } from 'ngx-cookie-service';
import { ResponseInterceptor } from '../common/response.interceptor';
import { SeoService } from '../services/seo.service';
import { MagicAuthService } from '../services/magic-auth.service';
import { CollectibleService } from '../services/collectible.service';
import { MatExpansionModule } from '@angular/material/expansion';
import { PopupModule } from '@app/components/popups/popup.module';
import { UiModule } from '@app/components/global/ui.module';

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  exports: [
    LoginPageComponent,
    RegisterComponent,
    VerifyPageComponent,
    MainPageComponent,
  ],
  declarations: [
    LoginPageComponent,
    RegisterComponent,
    VerifyPageComponent,
    MainPageComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ComponentsModule,
    SlickCarouselModule,
    PipesModule,
    RouterModule,
    MatSelectModule,
    MatInputModule,
    ToastrModule.forRoot(),
    MatSlideToggleModule,
    // GooglePayButtonModule,
    MatFormFieldModule,
    SharedModule,
    PopupModule,
    UiModule,
    SocialLoginModule,
    MatExpansionModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true,
    },
    AuthenticationService,
    ImageService,
    LandingService,
    DictionaryService,
    AppToastrService,
    ProfileService,
    PledgeService,
    CookieService,
    RoutingService,
    CollectibleService,
    GlobalObjectService,
    AudioService,
    AudioPlayerService,
    VideoService,
    SeoService,
    MagicAuthService,
  ],
})
export class PagesModule {}
