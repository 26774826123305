<div class="inner-card" #myDiv id="{{index + 'inner'}}">

  <div
    class="card"
    [class.flipped]="toggleProperty"
    #myCard
    id="{{index + 'card'}}"
  >
    <div class="front" #frontCard id="{{index}}">
      <div class="card-image-wrapper">
        <img
          *ngIf="!item?.typeEditions"
          class="card__background"
          loading="lazy"
          alt="project {{item?.projectTitle}}"
          src="{{item?.backgroundImage}}"
        />
        <img
          *ngIf="item?.typeEditions"
          class="card__background"
          loading="lazy"
          alt="project {{item?.projectTitle}}"
          src="{{item?.imgNFT}}"
        />
        <img
          class="sale_corner-img"
          *ngIf="item?.saleAmount"
          loading="lazy"
          alt="line"
          src="./assets/images/cards/rectangle_with_curve.svg"
        />
      </div>

      <a
        class="default-link link-block card-outer"
        [style.backgroundColor]="item?.cardColor"
        href="https://www.globalrockstar.com/projects/{{item?.slug}}"
        (click)="openSupplierProfile(item, $event)"
        [class.headliner]="item?.keepItAll && !item?.isCollectible && !item?.legacyHit?.isLegacyHit"
        [class.newcomer]="!item?.keepItAll && !item?.isCollectible && !item?.legacyHit?.isLegacyHit"
        [class.legacyHit]="item?.legacyHit?.isLegacyHit && !item?.isCollectible"
        [class.collectible]="item?.isCollectible"
      >
        <div class="card__header">
          <p class="card__header-col year">
            <span *ngIf="!item?.saleAmount">{{item?.publish_year}}</span>
            <span class="sale-off" *ngIf="item?.saleAmount"
            >{{item?.saleAmount}}% <br/>
              OFF</span
            >
          </p>
          <p class="card__header-col card__title">
            <img
              class="card__logo-title"
              loading="lazy"
              *ngIf="item?.enableLogo"
              alt="line"
              [src]="CARD_CONTENT[item.artistCategory || 'grs'].logo"
              src="./assets/images/logo/small-logo.png"
            />
          </p>

          <div class="card__header-col card__logo">

            <app-tooltip
              class="tooltip-component"
              [img]="CARD_CONTENT[item.artistCategory || 'grs'].icon"
              *ngIf="!item?.isCollectible && !item?.legacyHit?.isLegacyHit"
            >
              <span>{{CARD_CONTENT[item.artistCategory || 'grs'].t_text}}</span>
              <br><br>
              <a *ngIf="item.artistCategory && CARD_CONTENT[item.artistCategory].link"
                 [routerLink]="['/how-it-works']">Learn more</a>
            </app-tooltip>
            <app-tooltip
              class="tooltip-component"
              *ngIf="item?.isCollectible && !item?.legacyHit?.isLegacyHit"
              [img]="CARD_CONTENT['collectible'].icon"
            >
              <span>{{CARD_CONTENT['collectible'].t_text}}</span>
            </app-tooltip>
          </div>
        </div>

        <div class="card__content">
          <div class="play-button" (click)="playVideo(item)">
            <i class="fas fa-play"></i>
          </div>
        </div>
        <div class="info__holder">
          <div class="card__name">
            <h2 class="card__title-h2 card__name--padding">
              {{item?.cardArtistTitle || item?.artist?.name || item?.artist}}
            </h2>
            <h3 class="card__title-h3 card__name--padding">
              <ng-template [ngIf]="!item?.isAlbum">
                {{item?.song?.name || item?.song}}
              </ng-template>
              <ng-template [ngIf]="item?.isAlbum">
                {{item?.projectTitle}}
              </ng-template>
            </h3>
            <p class="card__description">{{item?.cardDescription}}</p>
          </div>
          <h3
            class="track__numbers"
            *ngIf="item?.isAlbum && item?.songs?.length > 1"
          >
            Tracks: {{item?.songs?.length}}
          </h3>
        </div>
        <div class="card__footer">
          <ng-template
            [ngIf]="item?.isCollectible && enableTimer && item?.projectNotStarted"
          >
            <div class="card-collectible__auction-sh">
              <div class="auction-time">
                <div class="auction__title">Auction starts in</div>
                <app-timer
                  [project]="item"
                  [config]="config"
                  [enableTimer]="enableTimer"
                ></app-timer>
              </div>
              <div class="footer-detail">
                <div class="footer-detail__icons">
                  <div class="icon-key" *ngIf="item?.limitedIcon">
                    <app-tooltip
                      class="tooltip-component"
                      [img]="'./assets/images/cards/key.png'"
                    >
                      By purchasing Music Collectibles you obtain a limited
                      ownership right to the recording.
                    </app-tooltip>
                  </div>
                  <div class="icon-gift" *ngIf="item?.goodiesIcon">
                    <app-tooltip
                      class="tooltip-component"
                      [img]="'./assets/images/cards/gift.svg'"
                    >
                      Goodies: This Music Collectible contains special goodies
                      for the top bidders.
                    </app-tooltip>
                  </div>
                </div>
                <p *ngIf="item?.typeEditions" class="auction-edition">
                  Edition: #{{item?.editionNumber}}
                </p>
                <p *ngIf="!item?.typeEditions" class="auction-edition">
                  Editions: {{item?.edition}}
                </p>
              </div>
            </div>
          </ng-template>
          <ng-template
            [ngIf]="item?.isCollectible && !item?.projectNotStarted && !item?.finished"
          >
            <div class="card-collectible__auction-run">
              <div class="footer-detail">
                <div class="footer-detail__icons">
                  <div class="icon-key" *ngIf="item?.limitedIcon">
                    <app-tooltip
                      class="tooltip-component"
                      [img]="'./assets/images/cards/key.png'"
                    >
                      By purchasing Music Collectibles you obtain a limited
                      ownership right to the recording.
                    </app-tooltip>
                  </div>
                  <div class="icon-gift" *ngIf="item?.goodiesIcon">
                    <app-tooltip
                      class="tooltip-component"
                      [img]="'./assets/images/cards/gift.svg'"
                    >
                      Goodies: This Music Collectible contains special goodies
                      for the top bidders.
                    </app-tooltip>
                  </div>
                </div>
                <p *ngIf="item?.typeEditions" class="auction-edition">
                  Edition: #{{item?.editionNumber}}
                </p>
                <p *ngIf="!item?.typeEditions" class="auction-edition">
                  Editions: {{item?.edition}}
                </p>
              </div>
              <div class="auction-time">
                <div class="auction__title">Auction ends in</div>
                <app-timer
                  [project]="item"
                  [config]="config"
                  [enableTimer]="enableTimer"
                ></app-timer>
              </div>
            </div>
          </ng-template>
          <ng-template
            [ngIf]="item?.isCollectible && item?.finished && !item?.typeEditions"
          >
            <div class="card__end" [style.backgroundColor]="item?.cardColor">
              <span>Sold out</span>
            </div>
          </ng-template>
          <ng-template
            [ngIf]="item?.isCollectible && item?.finished && item?.typeEditions"
          >
            <div class="card__end" [style.backgroundColor]="item?.cardColor">
              <span>AUCTION ENDED</span>
            </div>
          </ng-template>
          <ng-template [ngIf]="!item?.isCollectible">
            <ng-template
              [ngIf]="item?.daysLeft && !item?.isFunded && !item?.isCollectible"
            >
              <div class="headliner-project">
                <div class="price__per-share">
                  Price:
                  <span class="error-message" *ngIf="item?.salePricePerShare">
                    {{item?.salePricePerShare | localeCurrency | tenth}}</span
                  >
                  <span
                    [class.price__per-share-underline]="item?.salePricePerShare"
                  >{{item?.pricePerShare | localeCurrency | tenth}}</span
                  >
                  / 0.1%
                </div>
                <div class="headliner__icons">
                  <div class="icon-disk" *ngIf="item?.royaltiesFromMaster">
                    <app-tooltip
                      class="tooltip-component"
                      [img]="'./assets/images/cards/disk.svg'"
                    >
                      Master Rights: You will participate in the commercial
                      exploitation of the Master Rights for 70 years. Every time
                      the track is streamed, downloaded, licenced to TV, Film or
                      Ads or compiled on a physical product (CD, Vinyl) you will
                      earn proceeds.
                      <br/><br/>
                      <a
                        class="tooltip-link"
                        (click)="goToLink($event, STATE_NAMES?.howItWorks)"
                      >Learn more</a
                      >
                    </app-tooltip>
                  </div>
                  <div
                    class="icon-melody"
                    *ngIf="item?.royaltiesFromPublishingRights"
                  >
                    <app-tooltip
                      [img]="'./assets/images/cards/melody.svg'"
                      class="tooltip-component"
                    >
                      Publishing Rights: You will participate in the commercial
                      exploitation of the Publishing Rights. You will earn money
                      every time the work (lyrics & composition) is played in
                      radio, TV, live or licenced to film or advertisements.
                      <br/><br/>
                      <a
                        class="tooltip-link"
                        (click)="goToLink($event, STATE_NAMES?.howItWorks)"
                      >Learn more</a
                      >
                    </app-tooltip>
                  </div>
                </div>
                <div class="card__progress-bar">
                  <div
                    class="result"
                    [style.backgroundColor]="item?.cardColor"
                    [style.width]="item?.progressBar + '%'"
                  ></div>
                </div>
                <div class="card__share">
                  <div class="share__time-left">
                    <span
                      *ngIf="item?.daysLeft && !item.isFunded && item?.daysLeft > 1 && item?.daysLeft !== 1"
                      [class.daysleft]="item?.daysLeft <= 7"
                    >
                      {{item.daysLeft}} {{item.daysLeft > 1 ? 'days' : 'day'}}
                      to go
                    </span>
                    <div
                      *ngIf="item?.daysLeft === 1"
                      [class.daysleft]="item?.daysLeft === 1"
                    >
                      <span
                        *ngIf="this.calculateTimeDistance(endDate) > 3600000"
                      >{{displayHours | async}} to go</span
                      >
                      <span
                        *ngIf="this.calculateTimeDistance(endDate) > 0 && this.calculateTimeDistance(endDate) < 3600000"
                      >{{displayMinutes | async}} to go</span
                      >
                    </div>
                  </div>
                  <div class="price-item">
                    <span
                      *ngIf="item?.totalInvestorsLeft != item?.totalInvestorsShares"
                    >shares left:
                    </span>
                    <span
                      *ngIf="item?.totalInvestorsLeft === item?.totalInvestorsShares"
                    >available shares:
                    </span>
                    <span class="percent"
                    >{{item?.totalInvestorsLeft | number:'.0-1'}}%</span
                    >
                  </div>
                </div>
              </div>
            </ng-template>
            <div
              class="button card__end"
              [style.backgroundColor]="item?.cardColor"
              *ngIf="item?.isSold || item?.isFunded || item?.releaseCreated || item?.isFailed"
            >
              <ng-template
                [ngIf]="(item?.isSold || item?.isFunded) && !item?.releaseCreated && !item?.isFailed"
              >
                <span *ngIf="!item.statusCard">Sold out</span>
                <span *ngIf="item.statusCard">{{item.statusCard}}</span>
              </ng-template>
              <ng-template [ngIf]="item?.isFailed">Failed</ng-template>
              <ng-template [ngIf]="item?.releaseCreated">
                Released on {{item?.releaseCreatedDate |
                momentDateFormat}}</ng-template
              >
            </div>
          </ng-template>
        </div>
      </a>
    </div>
    <div
      class="back"
      (mouseenter)="hideElement = false"
      (mouseleave)="hideElement = true"
      [style.backgroundColor]="item?.cardColor"
    >
      <div class="card__content"></div>
      <div
        class="play-button"
        *ngIf="!hideElement && currentPlayingVideo?.buffered"
        (click)="pauseVideo()"
      >
        <i class="fas fa-stop"></i>
      </div>
      <video
        #myVideo
        class="video"
        id="{{(item?.id || item?._id) + 'video'}}"
        width="310px"
        height="473px"
        (playing)="videoPlaying = true"
        (pause)="videoPlaying = false"
        (ended)="pauseVideo()"
      >
        <source
          [src]="item.video"
          [type]="'video/'+ getVideoFormat(item.video)"
        />
      </video>
      <div
        class="card__video-overlay"
        [style.backgroundColor]="item?.cardColor"
        [class.opaque]="videoPlaying"
      ></div>
      <div
        class="play-button"
        *ngIf="!hideElement && currentPlayingVideo?.buffered"
        (click)="pauseVideo()"
      >
        <i class="fas fa-stop"></i>
      </div>
    </div>
  </div>
</div>
