import { Collection, LocaleName } from '@app/types';
import {IDictionary} from './interfaces';

export const HOMEPAGE_STATIC_IMG = [
  'apple',
  'google',
  'youtube',
  'pandora',
  'itunes',
  'spotify',
  'amazon',
  'tidal',
  'tiktok',
  'tencent',
  'deezer'
];
export const COMPANY_NUMBER_VALID = [
  'AT', 'BE', 'CY', 'EE', 'FI',
  'FR', 'GR', 'AD', 'BG', 'ES',
  'HR', 'CZ', 'DK', 'DE', 'HU',
  'IS', 'IE', 'IT', 'LV', 'LT',
  'LU', 'MT', 'MC', 'NO', 'NL',
  'PL', 'PT', 'RO', 'SI', 'SK',
  'SE', 'UK', 'GB',
  'CH', 'LI',
];
export const CARDS_STATIC_IMG = {
  'image-heart': 'heart.png',
  'image-arrow': 'arrow.png',
  'image-dollar': 'dollar.png',
  'image-increasing': 'increasing.png',
  'image-free': 'free.png'
};


export const STATIC_PAGE_SLUG = {
  terms: 'terms-and-conditions',
  imprint: 'terms-and-conditions',
  policy: 'data-policy'
};

export const HOME_MENU = ['all', 'headliner', 'newcomer', 'talents'];


export const PROJECT_STATUS = [
  {id: 1, name: 'Released', value: 'released'},
  {id: 2, name: 'Invest', value: 'invest'},
  {id: 3, name: 'Sold Out', value: 'soldOut'}
];
export const LANGUAGES = [
  {id: 'en', name: 'English'},
  {id: 'de', name: 'German'}
];

export const SEP_COUNTRIES = [
  'AT', 'BE', 'CY', 'EE', 'FI',
  'FR', 'GR', 'AD', 'BG', 'ES',
  'HR', 'CZ', 'DK', 'DE', 'HU',
  'IS', 'IE', 'IT', 'LV', 'LT',
  'LU', 'MT', 'MC', 'NO', 'NL',
  'PL', 'PT', 'RO', 'SI', 'SK',
  'SE', 'UK', 'GB', 'US', 'CA',
  'CH', 'LI', 'SM', 'VA'
];
export const MANGOPAY_LIST = [
  'FO', 'GE', 'GI', 'MK', 'IM',
  'MD', 'TR', 'VAT', 'BJ', 'BF',
  'CM', 'CV', 'KM', 'DJ', 'KE',
  'LS', 'MG', 'MW', 'MA', 'RW',
  'SH', 'SN', 'ZA', 'SR', 'SZ',
  'TZ', 'TG', 'AR', 'BQ', 'BR',
  'CL', 'CR', 'CW', 'DM', 'DO',
  'FK', 'HN', 'MX', 'PY', 'PE',
  'PR', 'MF', 'SV', 'UY', 'BT',
  'CN', 'TP', 'HK', 'IN', 'JP',
  'KR', 'MY', 'PH', 'SG', 'TW',
  'TH', 'VN', 'AU', 'CC', 'CK',
  'FJ', 'KI', 'NC', 'NZ', 'SB',
  'TO', 'AM', 'AZ', 'BH', 'IL',
  'JO', 'OM', 'QA', 'SA', 'AE',
  'GF', 'PF', 'GP', 'MQ', 'YT',
  'RE', 'BL', 'MF', 'PM'];
export const DOC_TYPE = [
  {id: 1, text: 'ID card'},
  {id: 2, text: 'Passport'},
];
export const DOC_MANGOPAY_TYPE = [
  {id: 1, name: 'id', text: 'ID card'},
  {id: 2, name: 'passport', text: 'Passport'},
  {id: 3, name: 'driver_license', text: 'Driver License'},
];

export const IMG_TRANS = {
  'Re-investment': 'fa-sync-alt',
  Investment: 'fa-arrow-down',
  Deposit: 'fa-arrow-down',
  'Proceeds distribution': 'fa-money-check-alt',
  'Refund Re-investment': 'fa-history',
  Refund: 'fa-history',
  'Pay-Out': 'fa-arrow-up',
  'Payout Refunded': 'fa-sync-alt',
  'Proceeds distribution Advance': 'fa-money-check-alt'
};
export const LINKS = {
  'Re-investment': 'fa-sync-alt',
  Investment: 'fa-arrow-down',
  'Proceeds distribution': 'fa-money-check-alt',
  'Refund Re-investment': 'fa-history',
  Refund: 'fa-history',
  'Pay-Out': 'fa-arrow-up',
  'Payout Refunded': 'fa-sync-alt',
  'Proceeds distribution Advance': 'fa-money-check-alt'
};
export const MANGOPAY_SA = {
  en: 'assets/files/Mangopay_Terms-EN.pdf',
  de: 'assets/files/Mangopay_Terms-DE.pdf'
};
export const PDF_DOCUMENTS = {
  information: 'assets/files/Informationsblatt_AltFG.pdf',
  policy: 'assets/files/Data_and_privacy_policy.pdf',
  terms: 'assets/files/TERMS_AND_CONDITIONS.pdf'
};

export const ROYALTY_PREVIEW = {
  diy: {
    masterPublishPrivate: 'assets/files/[DIY]MASTER & PUBLISHING - SONG - PRIVATE.pdf',
    masterPublishCompany: 'assets/files/[DIY]MASTER & PUBLISHING - SONG - COMPANY.pdf',
    masterPrivate: 'assets/files/[DIY]MASTER - SONG - PRIVATE.pdf',
    masterCompany: 'assets/files/[DIY]MASTER - SONG - COMPANY.pdf',
  },
  headliner: {
    en: {
      masterPublishPrivate: 'assets/files/[EN]MASTER & PUBLISHING - SONG - PRIVATE.pdf',
      masterPublishCompany: 'assets/files/[EN]MASTER & PUBLISHING - SONG - COMPANY.pdf',
      masterPrivate: 'assets/files/[EN]MASTER - SONG - PRIVATE.pdf',
      masterCompany: 'assets/files/[EN]MASTER - SONG - COMPANY.pdf',

      masterPublishPrivateAlbum: 'assets/files/[EN]MASTER & PUBLISHING - EP-ALBUM - PRIVATE.pdf',
      masterPublishCompanyAlbum: 'assets/files/[EN]MASTER & PUBLISHING - EP_ALBUM - COMPANY.pdf',
      masterPrivateAlbum: 'assets/files/[EN]MASTER - EP_ALBUM - PRIVATE.pdf',
      masterCompanyAlbum: 'assets/files/[EN]MASTER - EP-ALBUM - COMPANY.pdf'
    },
    de: {
      masterPublishPrivate: 'assets/files/[DE]MASTER & PUBLISHING - SONG - PRIVATE.pdf',
      masterPublishCompany: 'assets/files/[DE]MASTER & PUBLISHING - SONG - COMPANY.pdf',
      masterPrivate: 'assets/files/[DE]MASTER - SONG - PRIVATE.pdf',
      masterCompany: 'assets/files/[DE]MASTER - SONG - COMPANY.pdf',

      masterPublishPrivateAlbum: 'assets/files/[DE]MASTER & PUBLISHING - EP-ALBUM - PRIVATE.pdf',
      masterPublishCompanyAlbum: 'assets/files/[DE]MASTER & PUBLISHING - EP_ALBUM - COMPANY.pdf',
      masterPrivateAlbum: 'assets/files/[DE]MASTER - EP_ALBUM - PRIVATE.pdf',
      masterCompanyAlbum: 'assets/files/[DE]MASTER - EP-ALBUM - COMPANY.pdf'
    }
  }
};


export const COOKIES = {
  marketing: ['_ga', '_fbp', '_gid', '_gat'],
  statistics: ['_hj']
};
export const HINT_CARD = {
  headliner: 'Headliner Artists have already great successes with millions of streams and international radio\n' +
    '                      plays. You invest in keep-it-all opportunities which are hand-picked by Global Rockstar.\n' +
    '                      Headliner-songs will be produced in top quality and marketed in any case',
  newcomer: 'Newcomer Artists are working on their\n' +
    '                      first hit. If you believe in their song, invest and be part of an amazing production.\n' +
    '                      Newcomer-songs will be released and marketed only if all shares are sold (all-or-nothing\n' +
    '                      opportunities) - otherwise you will get your investment refunded 1:1'
};

export enum KYC_STATUS {
  PROCESSING = 'Processing',
  FAILED = 'Failed',
}

export enum AUTH_METHOD {
  GOOGLE = 'cd_google',
  APPLE = 'cd_apple',
}

export enum OAUTH_METHOD {
  GOOGLE = 'google',
  APPLE = 'apple',
}

export enum SORT_TYPE_MOBILE {
  DATE_PUBLISHED = 1,
  ENDING_SOON = 2,
  REMAINING_SHARES = 3,
}


export const SORT_TYPE: IDictionary[] = [{
  id: SORT_TYPE_MOBILE.DATE_PUBLISHED,
  name: 'Date published'
},
  {
    id: SORT_TYPE_MOBILE.ENDING_SOON,
    name: 'Remaining Days'
  },
  {
    id: SORT_TYPE_MOBILE.REMAINING_SHARES,
    name: 'Remaining Shares'
  },
];
export type t_selling = 'auction' | 'fixed_price' | 'third_party';

export type LEGAL_TYPEs = 'business' | 'organization' | 'soletrader';
export const LEGAL_TYPE = [{
  id: 'business',
  name: 'Legal business'
},
  {
    id: 'organization',
    name: 'Legal organization'
  },
  {
    id: 'soletrader',
    name: 'Legal soletrader'
  }
];
export const TYPE_USER = [{
  id: true,
  name: 'Private User'
},
  {
    id: false,
    name: 'Company'
  },
];

export const BANK_ACCOUNT_FORM_CONFIG: any = [
  {
    inputName: 'placeholder',
    includedCountries: null,
    excludedCountries: null,
    editable: true,
  },
  {
    inputName: 'IBAN',
    includedCountries: null,
    excludedCountries: ['US', 'CA', 'AU'],
  },
  { inputName: 'BSB', includedCountries: ['AU'], excludedCountries: null },
  {
    inputName: 'BIC',
    includedCountries: null,
    excludedCountries: ['US', 'CA', 'AU'],
  },
  { inputName: 'ABA', includedCountries: ['US'], excludedCountries: null },
  { inputName: 'BankName', includedCountries: ['CA'], excludedCountries: null },
  {
    inputName: 'DepositAccountType',
    includedCountries: ['US'],
    excludedCountries: null,
    forNewAccountOnly: true,
  },
  {
    inputName: 'BranchCode',
    includedCountries: ['CA'],
    excludedCountries: null,
    forNewAccountOnly: true,
  },
  {
    inputName: 'InstitutionNumber',
    includedCountries: ['CA'],
    excludedCountries: null,
    forNewAccountOnly: true,
  },
  {
    inputName: 'AccountNumber',
    includedCountries: ['US', 'CA', 'AU'],
    excludedCountries: null,
  },
];

export const CALCULATION_TABLES_ID: Collection = {
  PRICE: 'ORIGINAL_PRICE',
  SALE: 'SALE',
  EARNED: 'REVENUES',
  PROMOTION_CURRENCY: 'PROMO_CODE_CURRENCY',
  PROMOTION_PERCENTAGE: 'PROMO_CODE_PERCENT',
  VOUCHER: 'VOUCHER',
}

export const CALCULATION_TABLES: any = {
  PRICE: {
    id: CALCULATION_TABLES_ID.PRICE,
    text: 'Original Price',
    amount: 0,
    color: 'black',
    amountToDisplay: '{amount}'
  },
  SALE: {
    id: CALCULATION_TABLES_ID.SALE,
    text: 'Sale -{percent}% ',
    amount: 0,
    color: 'red',
    icon: 'green',
    amountToDisplay: '-{amount}'
  },
  EARNED: {
    id: CALCULATION_TABLES_ID.EARNED,
    text: 'Earned Revenues',
    amount: 0,
    color: 'green',
    icon: 'green',
    amountToDisplay: '-{amount}'
  },
  PROMOTION_EUR: {
    id: CALCULATION_TABLES_ID.PROMOTION_CURRENCY,
    text: 'Promo code',
    amount: 0,
    color: 'green',
    icon: 'green',
    amountToDisplay: '-{amount}'
  },
  PROMOTION_PERCENTAGE: {
    id: CALCULATION_TABLES_ID.PROMOTION_PERCENTAGE,
    text: 'Promo code',
    amount: 0,
    color: 'green',
    icon: 'green',
    amountToDisplay: '-{amount}%'
  },
  VOUCHER: {
    id: CALCULATION_TABLES_ID.VOUCHER,
    text: 'Voucher Balance',
    amount: 0,
    color: 'green',
    icon: 'green',
    amountToDisplay: '-{amount}'
  }
}

export const SLIDER_CONFIG: any = {
  HOME_DEFAULT: {
    arrows: false,
    infinite: true,
    centerMode: true,
    centerPadding: '60px',
    slidesToShow: 3,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          centerPadding: '24px',
          slidesToShow: 1,
        },
      },
    ],
  },
  HOME_SPOTLIGHT: {
    arrows: true,
    infinite: false,
    centerMode: false,
    centerPadding: '60px',
    slidesToShow: 1,
    variableWidth: true,
    dots: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          centerMode: true,
          infinite: true,
          centerPadding: '24px',
          slidesToShow: 1,
          dots: false,
        },
      },
    ],
  },
  HOME_SUCCESSES: {
    arrows: true,
    infinite: true,
    centerMode: true,
    centerPadding: '0px',
    slidesToShow: 3,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          arrows: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          centerPadding: '24px',
          slidesToShow: 1,
        },
      },
    ],
  },
};

export const LOCALE: Collection<LocaleName> = {
  US: 'US',
  OTHER: 'Other'
}
