import {CommonModule, DecimalPipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  CalculationComponent,
  LoaderComponent,
  MusicItemListComponent,
} from './index';
import {PipesModule} from '../pipes/pipes.module';
import {RouterModule} from '@angular/router';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {SongPreviewComponent} from './song-preview/song-preview.component';
import {SharedModule} from '../shared.module';
import {SubscribeComponent} from './popups/subscribe/subscribe.component';
import {VerifyEmailComponent} from './popups/verify-email/verify-email.component';
import {VerifyConditionsComponent} from './popups/verify-conditions/verify-conditions.component';
import {TfaPopupComponent} from './popups/tfa-popup/tfa-popup.component';
import {ScrollTopComponent} from './global/scroll-top/scroll-top.component';

import {SharePopupComponent} from './popups/share-popup/share-popup.component';
import {SongPlayerComponent} from './song-player/song-player.component';
import {VoucherPopupComponent} from './popups/voucher-popup/voucher-popup.component';
import {WithdrawMoneyComponent} from './popups/withdraw-money/withdraw-money.component';
import {PdfPopupComponent} from './popups/pdf-popup/pdf-popup.component';
import {ProfileRefundCostComponent} from './global/profile-refund-cost/profile-refund-cost.component';

import {FilterMobileComponent} from './filter-mobile/filter-mobile.component';
import {SortingHomeMobileComponent} from './sorting-home-mobile/sorting-home-mobile';
import {DowntimeComponent} from './downtime/downtime.component';
import {MenuSearchComponent} from './menu-search/menu-search.component';
import {TfaComponent} from './tfa/tfa.component';
import {TransferMoneyComponent} from './transfer-money/transfer-money.component';
import {TransferMoneyPopupComponent} from './popups/transfer-money-popup/transfer-money-popup.component';
import {GooglePayButtonModule} from '@google-pay/button-angular';
import {CardNewsComponent} from './cards/card-news/card-news.component';
import {CardInformationComponent} from './cards/card-information/card-information.component';
import {
   SubscribeNewsletterComponent
} from './global/subscribe-newsletter/subscribe-newsletter.component';
import {ProjectSongDetailsComponent} from './project-component/project-song-detailes/project-song-detailes.component';
import {ProjectBidsComponent} from './project-component/project-bids/project-bids.component';
import { CardNftComponent } from './cards/card-nft/card-nft.component';
import { CardCollectibleComponent } from './cards/card-collectible/card-collectible.component';
import {ReminderToKycComponent} from './popups/reminder-to-kyc/reminder-to-kyc.component';
import {MatRadioModule} from '@angular/material/radio';
import {DropdownComponent} from './global/dropdown/dropdown.component';
import {FooterAboutCompanyComponent} from './global/footer-about-company/footer-about-company.component';
import {OutsideDirective} from './global/tooltip/outside.directive';
import {BaseSelectAmountComponent} from './calculation/components/base-select-amount/base-select-amount.component';
import {ApplePayButtonComponent} from './payment-methods/apple-pay/apple-pay.component';
import {BankAccountComponent} from './bank-account/bank-account.component';
import { LoginFormComponent } from '@app/components/forms/login-form/login-form.component';
import { RegisterFormComponent } from './forms/register-form/register-form.component';
import {PopupModule} from "@app/components/popups/popup.module";
import {UiModule} from "@app/components/global/ui.module";
import { DirectivesModule } from '@app/directives/directives.module';
import { AppFormsModule } from './forms/app-forms.module';
import {ClipboardModule} from '@angular/cdk/clipboard';
@NgModule({
  exports: [
    MusicItemListComponent,
    SongPreviewComponent,
    LoaderComponent,
    CalculationComponent,
    SubscribeComponent,
    VerifyEmailComponent,
    VerifyConditionsComponent,
    TfaPopupComponent,
    ScrollTopComponent,
    FilterMobileComponent,
    SharePopupComponent,
    SongPlayerComponent,
    VoucherPopupComponent,
    WithdrawMoneyComponent,
    PdfPopupComponent,
    ProfileRefundCostComponent,
    SortingHomeMobileComponent,
    DowntimeComponent,
    MenuSearchComponent,
    CardNftComponent,
    CardCollectibleComponent,
    TfaComponent,
    TransferMoneyComponent,
    TransferMoneyPopupComponent,
    CardNewsComponent,
    CardInformationComponent,
    SubscribeNewsletterComponent,
    ProjectSongDetailsComponent,
    ProjectBidsComponent,
    ReminderToKycComponent,
    DropdownComponent,
    FooterAboutCompanyComponent,
    OutsideDirective,
    BaseSelectAmountComponent,
    ApplePayButtonComponent,
    BankAccountComponent,
    LoginFormComponent,
    RegisterFormComponent,
  ],
  declarations: [
    MusicItemListComponent,
    SongPreviewComponent,
    LoaderComponent,
    CalculationComponent,
    SubscribeComponent,
    VerifyEmailComponent,
    VerifyConditionsComponent,
    TfaPopupComponent,
    ScrollTopComponent,

    FilterMobileComponent,
    SharePopupComponent,
    SongPlayerComponent,
    VoucherPopupComponent,
    WithdrawMoneyComponent,
    PdfPopupComponent,

    ProfileRefundCostComponent,
    SortingHomeMobileComponent,
    DowntimeComponent,
    MenuSearchComponent,
    CardNftComponent,
    CardCollectibleComponent,
    TfaComponent,
    TransferMoneyComponent,
    TransferMoneyPopupComponent,
    CardNewsComponent,
    CardInformationComponent,
    SubscribeNewsletterComponent,
    ProjectSongDetailsComponent,
    ProjectBidsComponent,
    ReminderToKycComponent,
    DropdownComponent,
    FooterAboutCompanyComponent,
    OutsideDirective,
    BaseSelectAmountComponent,
    ApplePayButtonComponent,

    BankAccountComponent,
    LoginFormComponent,
    RegisterFormComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GooglePayButtonModule,
    PipesModule,
    RouterModule,
    UiModule,
    AppFormsModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    SharedModule,
    PopupModule,
    DirectivesModule,
    ClipboardModule,
  ],
  providers: [
    DecimalPipe
  ]
})
export class ComponentsModule {
}
