import {Component, Output, EventEmitter, OnInit, OnDestroy} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NUMBER_LENGTH_FOUR} from '../../../common';
import {CustomFieldErrorMatcher} from '../../../common/errorMatcher';
import {AppToastrService} from '../../../services';
import {GOOGLE_REQUEST} from '../../../common/googleRequest';
import {environment} from '../../../../environments/environment';
import {debounceTime} from 'rxjs/operators';
import {Subscription} from 'rxjs';

declare var google: any;

@Component({
  selector: 'app-transfer-money-popup',
  templateUrl: './transfer-money-popup.html'
})


export class TransferMoneyPopupComponent implements OnInit, OnDestroy {
  form: FormGroup;
  amountCtrl: FormControl;
  submitPressed: boolean;
  paymentMethod: string;
  googleWidth: string;
  envGoogleType: any;
  paymentRequest = GOOGLE_REQUEST;
  subscription: Subscription;
  disableProceedPay = false;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  constructor(private fb: FormBuilder, private toastr: AppToastrService) {
    this.envGoogleType = environment.googlePay;
    this.googleWidth = '100%';
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.amountCtrl = this.fb.control('', [Validators.required, Validators.pattern(NUMBER_LENGTH_FOUR)]);
    this.form = this.fb.group({
      amount: this.amountCtrl
    });
    this.subscription = this.amountCtrl.valueChanges.pipe(
      debounceTime(500),
    ).subscribe(value => {
      this.disableProceedPay = false;
      if (!this.amountCtrl.valid) {
        this.disableProceedPay = true;
        this.toastr.showToastFromError({error: {message: 'Amount not valid'}});
      } else if (this.amountCtrl.value > 2500) {
        this.disableProceedPay = true;
        this.toastr.showToastFromError({error: {message: 'Amount limit is 2500 euro'}});
      } else {
        this.paymentRequest.transactionInfo.totalPrice = value ? value : '0';
      }
    }, err => {
      this.toastr.showToastFromError(err);
    });
  }


  onLoadPaymentDataG(event: Event): any {
    this.paymentMethod = null;
    if (!this.amountCtrl.valid || this.amountCtrl.value > 2500) {
      this.toastr.showToastFromError({error: {message: 'Amount not valid'}});
      return false;
    }
    const eventDetail = event as CustomEvent<google.payments.api.PaymentData>;
    if (eventDetail) {
      this.submit(eventDetail);
    }

  }

  onClose() {
    this.notify.emit({type: 'closePopup'});
  }

  selectPaymentMethod(type: string, disabled?: boolean): void {
    if (!disabled && this.amountCtrl.valid && this.amountCtrl.value <= 2500) {
      this.paymentMethod = type;
    }
  }

  onPaymentDataAuthorized: google.payments.api.PaymentAuthorizedHandler = (paymentData) => {
    return {
      transactionState: 'SUCCESS'
    };
  }

  errorHandlerGoogle(e: ErrorEvent): void {
    this.toastr.showToastFromError(e.error);
  }

  errorMatcher(control: FormControl) {
    let error;
    const showError = this.submitPressed;
    return new CustomFieldErrorMatcher(control, showError, error);
  }

  submit(e?) {
    if (!this.amountCtrl.valid || this.amountCtrl.value > 2500) {
      this.toastr.showToastFromError({error: {message: 'Amount not valid'}});
      return false;
    } else {

      this.submitPressed = true;
      const data = {
        paymentMethod: !this.paymentMethod ? 'googlepay' : this.paymentMethod,
        amount: Number(this.amountCtrl.value),
        paymentData: e ? e.detail.paymentMethodData : null
      };
      this.notify.emit({type: 'submit', data});
    }
  }

}
