<div class="project__detail-song-information detail-song-info">
  <div class="detail-song-info__song-details song-details">
    <h1 class="flex-align-center item-content-title project__name">

                   <span *ngIf="project?.isAlbum" class="album-logo">
                    <i class="fas fa-list" *ngIf="project?.isAlbum"></i>
                   <span
                     class="item-content-title-album">{{project?.projectTitle}}</span>
                 </span>
        <span *ngIf="!project?.isAlbum && !project?.isCollectible">
                {{project?.song?.name}}
                </span>
        <span *ngIf="!project?.isAlbum && project?.isCollectible">
                {{project?.song}}
                </span>
    </h1>
    <div class="artist-name-wrapper">
      <div class="artist-name-item">
        <h2 class="artist-name-text">By {{project?.cardArtistTitle || project?.artist?.name}}</h2>
      </div>
      <div class="artist-name-item" *ngIf="project?.song?.genre || project?.genre">
        <img src="./assets/images/project/musical-note.png">
        <span class="count">{{project?.song?.genre || project?.genre}}</span>
      </div>
      <div class="artist-name-item" *ngIf="project?.artist?.country">
        <span class="lnr lnr-map-marker"></span>
        <span class="count">{{project?.artist?.country}}</span>
      </div>
      <div class="artist-name-item" *ngIf="project?.isCollectible">
        <img src="./assets/images/project/editions.png">
        <span class="count">{{project?.edition}} Editions</span>
      </div>
    </div>
    <p class="flex-align teaser" *ngIf="project?.teaser">
      {{project?.teaser}}
    </p>
    <div class="detail-song-info__static">
      <div class="detail-song-info__static-col" *ngFor="let itemTeaser of project?.offerTeaser">
        <img class="detail-song-info__img" alt="{{itemTeaser?.headline}}" src="{{itemTeaser?.icon}}">
        <div class="detail-song-info__static-text">
          <h3>{{itemTeaser?.headline}}</h3>
          <p>{{itemTeaser?.text}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="detail-song-info__grs-details grs-details">
    <h3 class="grs-details__title">Details</h3>
    <ng-container *ngTemplateOutlet="projectDetail; context: {project: project}"></ng-container>
  </div>
</div>
<ng-template #projectDetail let-project="project">
  <div class="grs-details__body">
    <ul class="grs-details__col">
      <li><p>Rights:</p>
        <p class="song-value">
          <ng-template [ngIf]="project?.isCollectible">
            {{project?.collectionRights}}
          </ng-template>
          <ng-template [ngIf]="!project?.isCollectible">
            {{project?.royaltiesFromMaster ? 'Master rights' : ''}}
            {{project?.royaltiesFromMaster && project?.royaltiesFromPublishingRights? ' & ' : ''}}
            {{project?.royaltiesFromPublishingRights ? 'Publishing rights' : ''}}
          </ng-template>
        </p>
      </li>
      <li><p>Type:</p>
        <p class="song-value">{{project?.isCollectible ? 'Music Collectible' : 'Participation Rights'}}</p></li>
      <li *ngIf="project?.isCollectible"><p>Collection:</p>
        <p class="song-value">{{project?.collectionName}}</p></li>
      <li><p>Rights Holder:</p>
        <p class="song-value">{{project?.rightsHolder}}</p></li>
      <li><p>Status:</p>
        <p class="song-value">{{project?.statusDetail}}</p></li>
      <li *ngIf="!project?.isCollectible"><p>Planned Song Release:</p>
        <p class="song-value">{{project?.datePlannedRelease}}</p></li>
      <li><p>Contract Address:</p>
        <p class="song-value"><a href="https://polygonscan.com/address/0xFB66799af0Aa4cD63cb660Ede462a4AdD13aCF52">0xFB667...13aCF52</a><img
          class="img-copy" src="./assets/images/cards/copy.png"
          [cdkCopyToClipboard]="'0xFB66799af0Aa4cD63cb660Ede462a4AdD13aCF52'"></p></li>
      <li><p>Token Standard:</p>
        <p class="song-value">{{project?.isCollectible ? 'ERC721' : 'ERC1155'}}</p></li>
      <li><p>Blockchain:</p>
        <p class="song-value">Polygon</p></li>
      <li class="grs-details__line"><p>{{project?.isCollectible ? 'Minimum Bid:' : 'Price:'}}</p>
        <p class="song-value"><span
          *ngIf="!project?.isCollectible">{{project?.priceForShare | localeCurrency | tenth}}</span>
          <span *ngIf="project?.isCollectible">{{project?.startingPrice | localeCurrency }}</span></p>
      </li>
      <li *ngIf="!project?.isCollectible"><p>Break-even:</p>
        <p class="song-value">{{project?.breakEven}}</p></li>
    </ul>
  </div>
</ng-template>
