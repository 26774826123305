import {NgModule} from '@angular/core';
import {MomentDatePipe} from './moment-date.pipe';
import {InnerHtmlPipe} from './inner-html.pipe';
import {InnerHtmlUrlPipe} from './inner-html-url.pipe';
import {MomentTypeZonePipe} from './moment-time-zone.pipe';
import {MomentDateFormatPipe} from './moment-date-format.pipe';
import { LocaleCurrencyPipe } from './locale-currency.pipe';
import { CurrencyPipe } from '@angular/common';
import { TenthPipe } from './tenth.pipe';
@NgModule({
  imports: [],
  providers: [LocaleCurrencyPipe, CurrencyPipe],
  declarations: [
    MomentDatePipe,
    InnerHtmlPipe,
    InnerHtmlUrlPipe,
    MomentTypeZonePipe,
    MomentDateFormatPipe,
    LocaleCurrencyPipe,
    TenthPipe,
  ],
  exports: [
    MomentDatePipe,
    InnerHtmlPipe,
    InnerHtmlUrlPipe,
    MomentTypeZonePipe,
    MomentDateFormatPipe,
    LocaleCurrencyPipe,
    TenthPipe
  ]
})

export class PipesModule {
}
