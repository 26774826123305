import {Injectable} from '@angular/core';
import {map, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {StorageService} from './storage.service';
import {SettingService} from './setting.service';
import {ArtistModel, ProjectsModel, RevenueModel, RequestModel, SliderModel, UserModel} from 'app/models';
import {FansModel} from '../models/fans.model';
import {NewsCardModel} from '../models/newsCard.model';
import {PledgesModel} from '../models/pledges.model';
import {UpcomingProjectModel} from '../models/upcomingProjects.model';
import {CollectibleModel} from '../models/collectible.model';
import {BidModel, BidModelList} from '../models/bid.model';
import {CardModel} from '../models/card.model';
import {IErrorBid} from '../common/interfaces';
import {ProjectLiveModel} from 'app/models/projectLive.model';

@Injectable({providedIn: 'root'})
export class ProjectsService {
  apiUrl: string;
  token: string;
  imgUrl: string;
  spotifyToken: string;
  artist: any;
  private _getVerification = new Subject<any>();
  private project: BehaviorSubject<ProjectsModel> = new BehaviorSubject(null);
  project$: Observable<ProjectsModel> = this.project.asObservable();

  constructor(private http: HttpClient,
              private setting: SettingService,
              private storage: StorageService) {
    this.apiUrl = this.setting.apiUrl;
    this.imgUrl = this.setting.imgUrl;
    this.token = this.storage.accessToken;
    this.spotifyToken = 'BQDAbjkc1UaTHOZGOfmHGzv6BdxqjdMSNwr2TS8BxNoKTszqfRLnDton1wmuVMY3oTdm5RD_rmO4iVjwhXJSgaplvLZOJ6RhWJuLlSyVIj6-yt3xnHiWDj3qZBeVIw-eSH6gW83Uy72fkaZRsPuJy_fuBmXyYSuuKcpQdIrO592m';

  }


  downloadCSV(request, type: string): Observable<any> {
    const params = request ? request.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/${type}/csv`, {params}).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  getArtistDetails(slug): Observable<any> {
    return this.http.get(`${this.apiUrl}/artist/${slug}/artist-label`).pipe(map((resp: any) => {
      this.artist = resp.artist;
      return {
        artist: resp.artist,
        news: resp.news.map(v => {
          return new NewsCardModel(this.imgUrl).setFromApiModel(v);
        }),
        projectFunded: resp.projectFunded,
        projectLive: resp.projectLive.map(v => {
          return new ProjectLiveModel(this.imgUrl, this.artist).setFromApiModel(v);
        })
      };
    }));
  }

  getAllProjects(request?: RequestModel): Observable<any> {
    const currency: string = this.setting.curency.toLowerCase();
    const params = request ? request.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/projects`, {params}).pipe(map((resp: any) => {
      if (resp) {
        let cards = [];
        const totalArray = resp.projects.map(item => new CardModel().setFromApiModel(item, currency, resp.enableLogo));
        let funded = [];
        let released = [];
        if (resp.funded) {
          funded = resp.funded.map(item => {
            item.isFunded = false;
            item.soldOut = true;
            return new CardModel().setFromApiModel(item, currency, resp.enableLogo);
          });
        }
        if (resp.released) {
          released = resp.released.map(item => {
            item.isFunded = false;
            item.soldOut = true;
            return new CardModel().setFromApiModel(item, currency, resp.enableLogo);
          });
        }
        if (resp.newsCard && Object.keys(resp.newsCard).length) {
          cards = resp.newsCard.map(item => new NewsCardModel(this.imgUrl).setFromApiModel(item));
        }
        const sliders = this.formatSlidersList(resp.landing);
        const usaSliders = this.formatSlidersList(resp.usaLanding);

        return {
          sliders: {
            list: sliders,
          },
          usaSliders: {
            list: usaSliders,
          },
          seo: resp.seo,
          totalArray,
          cards,
          funded,
          released,
          collectible: resp.collectible ? resp.collectible.map(item => new CardModel().setFromApiModel(item, currency, resp.enableLogo, true)) : [],
          total: resp.total,
          pNumber: resp.paginationNumber
        };
      }
    }));
  }

  private formatSlidersList(landing: { list: any[] }): any[] {
    if (!landing?.list) return [];

    return landing.list.map((item) =>
      new SliderModel(this.imgUrl).setFromApiModel(item))
  }

  getSpotlights(): Observable<CardModel [] | []> {
    const currency: string = this.setting.curency.toLowerCase();

    return this.http.get(`${this.apiUrl}/opportunities?isSpotlight=true`)
      .pipe(map((resp: any) => {
        return resp.list?.map((item: any) => new CardModel().setFromApiModel(item, currency, true)) || [];
      }));
  }

  _downloadCSV(request, type: string): Observable<any> {
    const params = request ? request.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/${type}/csv`, {params}).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  addEmptyItem(list): any[] {
    [1, 2, 3, 4].forEach(() => {
      list.push({isEmptyItem: true});
    });
    return list;
  }

  getUpcomingProject(): Observable<any> {
    return this.http.get(`${this.apiUrl}/upcoming-projects`).pipe(map((resp: any) => {
      return resp.map(item => new UpcomingProjectModel(this.imgUrl).setFromApiModel(item));
    }));
  }

  downloadTransactionCSV(request): Observable<any> {
    const params = request ? request.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/transactions/csv`, {params}).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  getMyBalance(request: RequestModel): Observable<any> {
    const params = request.getApiModel();
    return this.http.get(`${this.apiUrl}/transactions`, {params}).pipe(map((resp: any) => {
      if (resp) {
        return {
          transactions: resp.list.map(item => new PledgesModel().setBalanceApiModel(item)),
          total: resp.total
        };
      }
    }));
  }

  getAgreement(id: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/investments/${id}/royalty`, {responseType: 'blob'});
  }

  geStatement(id: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/statementFile/${id}`, {responseType: 'blob'});
  }

  downloadStatement(file: any, slug: string, extension: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/statementFile?extension=${extension}&slug=${slug}&timeDelivery=${file.timeDelivery}&statement=${file._id}`, {responseType: 'blob'});
  }

  getInvoice(id: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/investments/${id}/invoice`, {responseType: 'blob'});
  }

  getInvestments(request: RequestModel): Observable<any> {
    const curency: string = this.setting.curency.toLowerCase()
    const params = request.getApiModel();
    return this.http.get(`${this.apiUrl}/invested-projects`, {params}).pipe(map((resp: any) => {
      if (resp) {
        return {
          projects: resp.projects.map(item => new CardModel().setFromApiModel(item, curency, resp.enableLogo)),
          total: resp.total
        };
      }
    }));
  }

  getFavorites(): Observable<any> {
    return this.http.get(`${this.apiUrl}/favorite-artists`).pipe(map((resp: any) => {
      if (resp) {
        return resp.map(item => new ArtistModel(this.imgUrl).setFromApiModel(item));
      }
    }));
  }

  getArtistProjects(): Observable<any> {
    const curency: string = this.setting.curency.toLowerCase()

    return this.http.get(`${this.apiUrl}/my-projects?status=all`).pipe(map((resp: any) => {
      if (resp) {
        return {
          opportunity: resp.myProject && resp.myProject.length ? resp.myProject.map(item => new UpcomingProjectModel(this.imgUrl).setFromApiModel(item)) : [],
          active: resp.active.map(item => new CardModel().setFromApiModel(item, curency, resp.enableLogo)),
          funded: resp.funded.map(item => new CardModel().setFromApiModel(item, curency, resp.enableLogo)),
          count: resp.countOfActive
        };
      }
    }));
  }

  getUrl(): Observable<any> {
    return this.http.get('').pipe(map((resp: any) => {
      if (resp) {

      }
    }));
  }

  becomeFan(slug, isUpcoming?: boolean): Observable<any> {
    return this.http.post(`${this.apiUrl}/artist/${slug}/fan`, {isUpcoming}).pipe(map((resp: any) => {
      return resp.map(item => new FansModel(this.imgUrl).setFromApiModel(item));
    }));
  }

  becomeUpcomingInvestor(slug, typeOfStat): Observable<any> {
    return this.http.post(`${this.apiUrl}/project-upcoming/${slug}/investor`, {data: typeOfStat}).pipe(map((resp: any) => {
      return resp;
    }));
  }

  getProject(slug: string): Observable<ProjectsModel> {
    const currency: string = this.setting.curency.toLowerCase();

    return this.http.get(`${this.apiUrl}/project/${slug}`).pipe(
      map((resp: any) => {
        if (resp) {
          return new ProjectsModel(this.imgUrl).setFromFullApiModel(
            resp.project, currency
          );
        }
      }),
      tap((projectModel: ProjectsModel) => {
        this.project.next(projectModel);
      })
    );
  }

  getVideoUrl(slug: string, isCollectible: boolean): Observable<any> {
    return this.http.get(`${this.apiUrl}/project/${slug}/video?collectible=${!!isCollectible}`).pipe(map((resp: any) => {
      if (resp) {
        return {data: resp, imgURL: this.imgUrl};
      }
    }));
  }

  getCollectible(slug: string, request?: RequestModel): Observable<CollectibleModel> {
    const params = request ? request.getApiModel() : undefined;
    return this.http.get(`${this.apiUrl}/collectible/${slug}`, {params}).pipe(map((resp: any) => {
      if (resp) {
        return new CollectibleModel(this.imgUrl).setFromApiModel(resp);
      }
    }));
  }

  getBidDetails(status: string, wallet: string, auction_id: string): Observable<BidModelList> {
    return this.http.get(`${this.apiUrl}/collectible/${auction_id}/user/${wallet}?` + `status=${status}`)
      .pipe(map((resp: any) => {
        if (resp) {
          const winningBids = resp.WinningBids ? resp.WinningBids.map(item => new BidModel().setFromApiModel(item, true)) : [];
          const losingBids = resp.LoosingBids ? resp.LoosingBids.map(item => new BidModel().setFromApiModel(item, false)) : [];
          return {
            bids: [...winningBids, ...losingBids]
          };
        }
      }));
  }

  getAllBids(user: UserModel, request): Observable<any> {
    const params = request ? request.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/user/bids`, {params})
      .pipe(map((resp: any) => {
        return {
          total: resp.total,
          auctions: resp.list ? resp.list.filter(item => item).map(item => {
            return item.WinningTokenPriority ? new BidModel().setFromApiModel(item, true) : new BidModel().setFromApiModel(item, false);
          }) : []
        };
      }));
  }

  makeBid(slug: string, body): Observable<IErrorBid> {
    return this.http.post(`${this.apiUrl}/collectible/${slug}/bid`, body).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  _remindMe(id: string, body): Observable<boolean> {
    return this.http.put(`${this.apiUrl}/collectible/${id}/remind`, body).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  _updateBid(slug: string, bidId: string, body): Observable<IErrorBid> {
    return this.http.put(`${this.apiUrl}/collectible/${slug}/bid/${bidId}`, body).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  getSongById(id: string): Observable<UpcomingProjectModel> {
    return this.http.get(`${this.apiUrl}/song/${id}`).pipe(map((resp: any) => {

      if (resp) {
        return new UpcomingProjectModel(this.imgUrl).setFromApiModel(resp);
      }
    }));
  }

  getProjectUpcoming(slug: string): Observable<UpcomingProjectModel> {
    return this.http.get(`${this.apiUrl}/project-upcoming/${slug}`).pipe(map((resp: any) => {
      if (resp) {
        return new UpcomingProjectModel(this.imgUrl).setFromFullApiModel(resp);
      }
    }));
  }

  getYouTubeUserId(link): Observable<any> {
    return this.http.get('https://www.googleapis.com/youtube/v3/channels?forUsername=' +
      link +
      '&key=AIzaSyAS_6IcvGnvXXTo7rBiDhXaevnA5XILRN4').pipe(map((resp: any) => {
      if (resp) {
        return resp.items[0].id;
      }
    }));
  }

  getYouTubeCount(slug: string, id: string): Observable<any> {
    let userId;
    userId = id;
    return this.http.get<any>
    ('https://www.googleapis.com/youtube/v3/channels?part=statistics&id=' +
      userId +
      '&key=AIzaSyAS_6IcvGnvXXTo7rBiDhXaevnA5XILRN4');
  }

  getSpotifyCount(slug: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/project/${slug}`).pipe(map((resp: any) => {
      if (resp) {
        const myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' +
          this.spotifyToken);

        const requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow' as RequestRedirect
        };

        return fetch('https://api.spotify.com/v1/artists/62HIEB2Mqv6jq1259A4zMz', requestOptions);
      }
    }));
  }

  getMyRevenues(request: RequestModel): Observable<any> {
    const params = request.getApiModel();
    return this.http.get(`${this.apiUrl}/revenues`, {params}).pipe(map((resp: any) => {
      if (resp) {
        return {
          revenues: resp.list.map(item => new RevenueModel().setFromApiModel(item)),
          total: resp.total
        };
      }
    }));
  }

  getMyInvestments(request: RequestModel): Observable<any> {
    const params = request.getApiModel();
    const curency: string = this.setting.curency.toLowerCase()
    return this.http.get(`${this.apiUrl}/investments`, {params}).pipe(map((resp: any) => {
      if (resp) {
        return {
          investments: resp.list.map(item => new ProjectsModel(this.imgUrl).setProjectPledgeModel(item, curency)),
          total: resp.total,
          totalInvest: resp.totalPledged
        };
      }
    }));
  }
  verifyStatusChanged() {
    return this._getVerification.asObservable();
  }

  verifyPopup(data) {
    this._getVerification.next(data);
  }

}
