import {
  Component,
  ElementRef,
  EventEmitter,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  Output,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { map, distinctUntilChanged } from 'rxjs';

import { TokenNFTmodel } from '@app/models/tokenNFT.model';
import { IProjectNFTSong } from '@app/interfaces/nft.interface';
import { extractFileExtension } from '@app/common/helpers';

// Consts
import { STATE_NAMES } from '../../../common';

// Services
import { VideoService } from '@app/services/video.service';

@UntilDestroy()
@Component({
  selector: 'app-card-nft',
  templateUrl: './card-nft.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardNftComponent implements OnInit {
  toggleProperty: boolean = false;
  currentPlayingVideo: HTMLVideoElement;
  videoObj: {
    video: HTMLVideoElement;
    card: any;
    status: string;
    index: number;
    front: HTMLElement;
  };
  showPause: boolean = false;
  currentDate: Date = new Date();

  @Input() item: TokenNFTmodel;
  @Input() song: IProjectNFTSong;
  @Input() index: number;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  @Output() videoObject: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('myDiv') myDiv: ElementRef;
  @ViewChild('myCard') myCard: ElementRef;
  @ViewChild('frontCard') frontCard: ElementRef;
  @ViewChild('myVideo') myVideo: ElementRef;

  constructor(
    private router: Router,
    private videoService: VideoService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.videoService.state$
      .pipe(
        untilDestroyed(this),
        map((state) => state.playing),
        distinctUntilChanged()
      )
      .subscribe((isPlaying) => {
        if (this.videoService.getCurrentVideo() === this.currentPlayingVideo) {
          this.toggleProperty = isPlaying;
          this.cdr.detectChanges();
        } else if (this.toggleProperty) {
          this.toggleProperty = false;
          this.cdr.detectChanges();
        }
      });
  }

  playVideo(item): void {
    this.currentPlayingVideo = this.myVideo.nativeElement;
    this.currentPlayingVideo.setAttribute('playsinline', '1');
    if (this.iOSversion()) {
      const front = this.frontCard.nativeElement;
      front.setAttribute('style', 'opacity:0');
    }

    this.videoObj = {
      video: this.currentPlayingVideo,
      card: item,
      status: 'play',
      index: this.index,
      front: this.myCard.nativeElement,
    };
    this.videoObject.emit(this.videoObj);
  }

  goToLink(e, link): void {
    e.preventDefault();
    this.router.navigate([`/${STATE_NAMES.howItWorks}`]);
  }

  onPlayingVideo(event, item) {
    event.preventDefault();
  }

  pauseVideo(item?, i?, div?) {
    if (!this.currentPlayingVideo) return;

    this.currentPlayingVideo.currentTime = 0;

    this.videoObj = {
      video: this.currentPlayingVideo,
      card: item,
      status: 'stop',
      index: this.index,
      front: this.myCard.nativeElement,
    };
    this.videoObject.emit(this.videoObj);
  }

  iOSversion() {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
      const ver = [
        parseInt(v[1], 10),
        parseInt(v[2], 10),
        parseInt(v[3] || (0 as any), 10),
      ];
      if (ver[0] < 15) {
        return true;
      } else {
        return false;
      }
    }
  }

  getVideoFormat(url: string): string {
    if (!url) {
      return 'mp4';
    }
    return extractFileExtension(url);
  }
}
