import {Component, OnDestroy, OnInit, OnChanges, Input, Output, EventEmitter, Inject, PLATFORM_ID} from '@angular/core';
import {SettingService} from 'app/services';
import {AudioPlayerService} from '../../services/audioPlayer.service';
import {ProjectsService} from '../../services/projects.service';


@Component({
  selector: 'app-song-player',
  templateUrl: './song-player..html'
})
export class SongPlayerComponent implements OnInit, OnDestroy, OnChanges {
  state: any;
  audioServiceSubscription: any;
  host: string;
  firstTime = true;
  wouldInvestActive: boolean;
  @Input() project: any;
  @Input() song: any;
  @Input() stopPlaying: any;
  @Input() wouldInvestActiveChange: boolean;
  @Input() user: any;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  constructor(private audioService: AudioPlayerService,
              private projectService: ProjectsService,
              private settingService: SettingService) {
    this.host = settingService.imgUrl;
  }

  ngOnInit() {
    this.playStream(this.song.soundtrack);
    this.audioServiceSubscription = this.audioService.getState().subscribe(state => {
      if (state.url === (`${this.host}/${this.song.soundtrack}`)) {
        this.state = state;
      }
    });

  }

  ngOnChanges(data) {
    if (data.stopPlaying && data.stopPlaying.currentValue >= 0) {
      if (this.song.hasOwnProperty('audObj') && data.stopPlaying.currentValue !== this.song.index) {
        this.audioService.stop(this.song.audObj.audPlayer);
        this.song.audObj.playing = false;
      }
    }
  }

  ngOnDestroy() {
    if (this.audioServiceSubscription) {
      this.audioServiceSubscription.unsubscribe();
    }
    this.project.songs.find((res) => {
      if (res.hasOwnProperty('audObj') && res.audObj.playing) {
        this.audioService.stop(res.audObj.audPlayer);
        res.audObj.playing = false;
      }
    });
  }

  playStream(url) {
    this.audioService.stop();
    this.audioService.playStream(`${this.host}/${url}`).subscribe((events: any) => {
      if (this.state.playing && this.firstTime) {
        this.firstTime = false;
        this.audioService.pause(this.state.audPlayer);
      }
      if (events.type === 'playing') {
        this.song.songIsPlaying = true;
      } else if (events.type === 'error') {
        this.song.songIsPlaying = false;
      }
    });
  }

  stopWhenPreview() {
    this.project.songs.find((res) => {
      if (res.hasOwnProperty('audObj') && res.audObj.playing) {
        this.audioService.stop(res.audObj.audPlayer);
        res.audObj.playing = false;
      }
    });
  }

  togglePlayer() {
    if (this.state && !this.state.error) {
      if (this.state.playing) {
        this.audioService.pause(this.state.audPlayer);
        this.song.songIsPlaying = false;
      } else {
        this.notify.emit({type: 'playMusic', data: this.state});
        this.audioService.play(this.state.audPlayer);
      }
    }

  }
}
