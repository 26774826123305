import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { UserModel } from '@app/models';

// Consts
import { NAVIGATION } from '@app/common/navigation';

// Services
import { ProfileService } from 'app/services';
import { StorageService } from '../../../services/storage.service';
import { GetLocaleService } from '@app/services/get-locale.service';
@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.html',
  styleUrls: ['./header.scss'],
})
export class GlobalHeaderComponent implements OnInit {
  public readonly NAVIGATION: any = NAVIGATION;

  user$: Observable<any> = this.profileService.user$.pipe(untilDestroyed(this));

  currentPath: string = '/';

  isUSA$: Observable<boolean> = this.localeService.isUsLocale$.pipe(
    tap(() => {
      this.idxIframeView = this.localeService.isIframeEntry;
      this.cdr.detectChanges();
      if(this.idxIframeView) {
        this.document.body.style.paddingTop = '75px';
      }
    }),
    untilDestroyed(this)
  );

  idxIframeView = this.localeService.isIframeEntry;

  constructor(
    private headerElement: ElementRef,
    private router: Router,
    public route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private profileService: ProfileService,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: Document,
    private storage: StorageService,
    private localeService: GetLocaleService
  ) {}

  @HostListener('window:scroll') windowScroll() {
    if (isPlatformBrowser(this.platformId)) {
      const element: HTMLElement =
        this.headerElement.nativeElement.querySelector('header');
      if (window.pageYOffset > element.clientHeight) {
        element.classList.add('scrolled');
      } else {
        element.classList.remove('scrolled');
      }
    }
  }

  ngOnInit(): void {
    this.profileService.emitProfileUpdate(this.storage.user);
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.currentPath = this.router.url;
      });
  }

  private checkPermitions(navList): any[] {
    const user: UserModel = this.profileService.syncGetUser();
    return navList.filter((navItem) => {
      if (!navItem.permitions) {
        return true;
      } else if (navItem.permitions) {
        return navItem.permitions.some((permition: string) => user[permition]);
      }
      return false;
    });
  }

  get profileNavList(): any[] {
    return this.checkPermitions(this.NAVIGATION.PROFILE);
  }

  get isActionButtonHidden(): boolean {
    return this.currentPath === '/api/wizard';
  }

  handleProfileClick(): void {
    if (!this.profileService.syncGetUser()) {
      this.router.navigate([NAVIGATION.LOGIN]);
    }
  }

  navigate(path: string, event: MouseEvent): void {
    event.preventDefault();
    this.router.navigate([path]);
  }
}
