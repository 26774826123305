<div class="inner-card" #myDiv>
  <div
    class="card"
    [class.flipped]="toggleProperty"
    #myCard
    id="{{this.index + 'card'}}"
  >
    <div class="front" #frontCard id="{{index}}">
      <div class="card-image-wrapper">
        <img
          class="card__background"
          loading="lazy"
          [alt]="'image of' + song?.name"
          [src]="song?.nft.imageURL"
        />
      </div>
      <a class="default-link link-block card-outer">
        <div class="card__header">
          <p class="card__header-col year">{{ currentDate | date: 'yyyy' }}</p>
          <p class="card__header-col card__title">
            <img
              class="card__logo-title"
              loading="lazy"
              title="GlobalRockstar logo"
              alt="GlobalRockstar logo"
              src="./assets/images/logo/small-logo.png"
            />
          </p>
          <div class="card__header-col card__logo">
            <app-tooltip
              class="tooltip-component"
              [img]="'./assets/images/logo/logo-white.svg'"
            >
              Global Rockstar Artists are directly signed to the Global Rockstar
              Music Label and/or Publishing and supported in longterm contracts
              by the company.
            </app-tooltip>
          </div>
        </div>
        <div class="card__content">
          <div class="play-button" (click)="playVideo(item)">
            <i class="fas fa-play"></i>
          </div>
        </div>
        <div class="card__name">
          <div>
            <h2 class="card__title-h2 card__name--padding">
              {{item?.artist?.name}}
            </h2>
            <h3 class="card__title-h3 card__name--padding">
              {{item?.isAlbum?item?.projectTitle: song?.name }}
            </h3>
            <h4
              *ngIf="item?.isAlbum"
              class="card__title-h4 card__name--padding"
            >
              {{song?.name}}
            </h4>
          </div>
          <div
            *ngIf="item?.isAlbum && item?.songs?.length > 1"
            class="track-numbers"
          >
            Track: {{song?.orderId}} of {{item?.songs?.length}}
          </div>
        </div>
        <div class="card__footer">
          <div
            class="headliner__icons footer-text"
            *ngIf="item?.royaltiesFromMaster || item?.royaltiesFromPublishingRights"
          >
            <div class="icon-disk" *ngIf="item?.royaltiesFromMaster">
              <app-tooltip
                class="tooltip-component"
                [img]="'./assets/images/cards/disk.svg'"
              >
                Master Rights: You will participate in the commercial
                exploitation of the Master Rights for 70 years. Every time the
                track is streamed, downloaded, licenced to TV, Film or Ads or
                compiled on a physical product (CD, Vinyl) you will earn
                proceeds.
                <br /><br />
                <a
                  class="tooltip-link"
                  (click)="goToLink($event, STATE_NAMES?.howItWorks)"
                  >Learn more</a
                >
              </app-tooltip>
            </div>
            <div
              class="icon-melody"
              *ngIf="item?.royaltiesFromPublishingRights"
            >
              <app-tooltip
                [img]="'./assets/images/cards/melody.svg'"
                class="tooltip-component"
              >
                Publishing Rights: You will participate in the commercial
                exploitation of the Publishing Rights. You will earn money every
                time the work (lyrics & composition) is played in radio, TV,
                live or licenced to film or advertisements.
                <br /><br />
                <a
                  class="tooltip-link"
                  (click)="goToLink($event, STATE_NAMES?.howItWorks)"
                  >Learn more</a
                >
              </app-tooltip>
            </div>
            <span class="rights-text" *ngIf="item?.royalties"
              >{{item?.royalties}}</span
            >
          </div>
          <div class="headliner-project">
            <div
              class="price__per-share card__end"
              [style.backgroundColor]="item?.cardColor"
            >
              {{song?.shares | number: '.1-1'}}%
            </div>
          </div>
        </div>
      </a>
    </div>
    <div
      class="back"
      (mouseenter)="showPause = true"
      (mouseleave)="showPause = false"
    >
      <div class="card__content">
        <div
          class="play-button"
          *ngIf="showPause && currentPlayingVideo?.buffered"
          (click)="pauseVideo()"
        >
          <i class="fas fa-stop"></i>
        </div>
      </div>
      <video
        class="video"
        (ended)="pauseVideo($event)"
        (playing)="onPlayingVideo($event, item)"
        (play)="onPlayingVideo($event, item)"
        preload="auto"
        #myVideo
        [id]="index + 'video'"
        width="310px"
        height="473px"
      >
        <source
          [src]="song?.nft?.videoURL"
          [type]="'video/'+ getVideoFormat(song?.nft?.videoURL)"
        />
      </video>
    </div>
  </div>
</div>
