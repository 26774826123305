<table>
  <tbody class="nested__body">
    <tr class="nested__tr" *ngFor="let item of revenue?.revenueHistory">
      <td>{{item?.dateDistribution | momentDateFormat}}</td>
      <td class="non-mobile">{{item?.name}}</td>
      <td class="revenue-icon">
        <app-tooltip [img]="getRevenueIcon(item).icon">
          {{getRevenueIcon(item).text}}
        </app-tooltip>
      </td>
      <td class="non-mobile">{{item?.date | momentDateFormat}}</td>
      <td class="non-mobile">
        <ng-container *ngIf="item?.transactionId; else noContent">
          <span>ID: {{item?.transactionId}}</span>
        </ng-container>
      </td>
      <td class="nested__amount">{{item?.amount | localeCurrency}}</td>
      <td class="nested__actions">
        <ng-container *ngIf="item?.report">
          <span class="nested__action">
            <a class="link-blue report"
              ><span (click)="loadReport(item, true)">Report</span>
              <i class="fas fa-download" (click)="loadReport(item, false)"></i>
            </a>
          </span>
        </ng-container>
        <span class="nested__action">
          <a
            href="#"
            class="link-blue preview"
            [class.iso-mobile]="ios"
            (click)="loadStatement(item, true, $event)"
            >Statement</a
          >
          <i
            class="fas fa-download"
            (click)="loadStatement(item, false, $event)"
          ></i>
          <app-loader
            class="download-loaded nested__loader"
            *ngIf="item?.download"
          ></app-loader>
        </span>
      </td>
    </tr>
  </tbody>
</table>
<ng-template #noContent>
  <span>-</span>
</ng-template>
