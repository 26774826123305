export const DEFAULT_SONG_IMG = 'assets/images/song-default.png';

export const VOUCHER_IMG = [
  {text: 'voucher_4', img: './assets/images/voucher/4.jpg'},
  {text: 'voucher_1', img: './assets/images/voucher/1.jpg'},
  {text: 'voucher_2', img: './assets/images/voucher/2.jpg'},
  {text: 'voucher_3', img: './assets/images/voucher/3.jpg'},
  {text: 'voucher_5', img: './assets/images/voucher/5.jpg'},
  {text: 'voucher_6', img: './assets/images/voucher/6.jpg'},
  {text: 'voucher_7', img: './assets/images/voucher/7.jpg'},
  {text: 'voucher_8', img: './assets/images/voucher/8.jpg'},
  {text: 'voucher_9', img: './assets/images/voucher/9.jpg'}
  ];
