import {
  ChangeDetectionStrategy,
  Component, ElementRef, EventEmitter, Inject,
  Input, OnInit, Output, PLATFORM_ID, ViewChild,
} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {STATE_NAMES} from '../../../common';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {SongPreviewComponent} from '../../song-preview/song-preview.component';
import {ProjectsService} from '../../../services/projects.service';
import { VideoService } from '@app/services/video.service';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {GoogleAnalyticsService} from "@services/google-analytics.service";
import { extractFileExtension } from '@app/common/helpers';
import {CARD_CONTENT} from "@app/common/content/card-page.content";


@UntilDestroy()
@Component({
  selector: 'app-card-project',
  templateUrl: './card-project.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardProjectComponent implements OnInit {
  toggleProperty: boolean;
  showLabel = true;
  showUploadPopup: boolean;
  showTooltip: any;
  isSEP: boolean;
  config = {
    colorTimer: '',
  };
  displayMinutes = new Subject();
  displayHours = new Subject();
  selectedProject: any;
  videoPlaying: any;

  currentPlayingVideo: HTMLVideoElement;
  videoObj = {};
  endDate: number;
  hideElement = true;
  public readonly CARD_CONTENT: any = CARD_CONTENT;
  @Input() item;
  @Input() index;
  @Input() enableTimer;
  @Input() isProject;
  @Input() exportItem: boolean;
  @Input() subscribeToVideoService: boolean = false;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(SongPreviewComponent, {static: false}) songPreview: SongPreviewComponent;
  @Output() enablePlayer: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('myDiv') myDiv: ElementRef;
  @ViewChild('myCard') myCard: ElementRef;
  @ViewChild('frontCard') frontCard: ElementRef;
  @ViewChild('myVideo') myVideo: ElementRef;

  constructor(@Inject(PLATFORM_ID) private platformId: any,
              private projectService: ProjectsService,
              private googleAnalytics: GoogleAnalyticsService,
              private router: Router,
              private videoService: VideoService,) {
  }

  ngOnInit(): void {
    if (this.item && this.item.editionNumber) {
      this.item.typeEditions = true;
      this.item.enableLogo = true;
     // this.config.colorTimer = this.item.cardColor;
    }
    if (this.index === undefined) {
      this.index = 1;
    }
    if (isPlatformBrowser(this.platformId)) {
      this.checkDate();
    }

    if(this.subscribeToVideoService) {

    this.videoService.getState()
      .pipe(
        untilDestroyed(this),
        map(state => state.playing),
        distinctUntilChanged(),
        filter((isPaused) => !isPaused),
      )
      .subscribe(()=> {
        if((this.videoObj as any).card) {
          this.pauseVideo((this.videoObj as any).card);
        }
      })
    }
  }

  calculateTimeDistance(endDate?): number {
    // Set Austrian time
    //const countDownDate = new Date().setUTCHours(21, 59, 59, 999);

    const countDownDate =  endDate ? endDate : new Date().setUTCHours(21, 59, 59, 999);
    const now = new Date().getTime();

    // Find the distance between now and displayHoursthe count down date
    return countDownDate - now;
  }

  playVideo(item): void {
    this.googleAnalytics.logSelectItem(item);

    this.projectService.getVideoUrl(item.slug, item.isCollectible).subscribe(resp => {
      if (resp.data.video) {
        item.video = `${resp.imgURL}/${resp.data.video}`;

        this.flipCard();

        const inner = this.myDiv.nativeElement;
        inner.style.perspective = '1000px';

        const card = this.myCard.nativeElement;
        card.setAttribute('class', 'card flipped');
        card.style.transformStyle = 'preserve-3d';
        card.style.transition = 'transform 1.5s';


        this.currentPlayingVideo = this.myVideo.nativeElement;
        this.currentPlayingVideo.setAttribute('playsinline', '1');
        this.videoObj = {
          video: this.currentPlayingVideo,
          card: item,
          status: 'play',
          index: this.index
        };
        this.enablePlayer.emit(this.videoObj);


        const front = this.frontCard.nativeElement;
        front.style.perspective = '0px';

        const mac = /(Mac|macos)/i.test(navigator.platform);
        if (this.iOSversion() || mac) {
          front.setAttribute('style', 'opacity:0');
        }

      }
    });
  }

  flipCard() {
    const cards = document.querySelectorAll('.card');
    for (let i = 0; i < cards.length; i++) {
      if (cards[i].classList.contains('flipped')) {
        cards[i].classList.remove('flipped');
        const mac = /(Mac|macos)/i.test(navigator.platform);
        if (this.iOSversion() || mac) {
          cards[i].firstElementChild.setAttribute('style', 'opacity:unset');
        }
      }
    }
  }

  goToLink(e, link): void {
    e.preventDefault();
    e.stopPropagation();
    this.router.navigate([`/${STATE_NAMES.howItWorks}`]);
  }

  pauseVideo(item?, i?) {
    this.currentPlayingVideo.currentTime = 0;

    this.videoObj = {
      video: this.currentPlayingVideo,
      card: item,
      status: 'stop',
      index: this.index
    };
    this.enablePlayer.emit(this.videoObj);

    const mac = /(Mac|macos)/i.test(navigator.platform);
    if (this.iOSversion() || mac) {
      if (i) {
        const front = document.getElementById(i) as HTMLElement;
        front.setAttribute('style', 'opacity:unset');
      }
      const front = this.frontCard.nativeElement;
      front.setAttribute('style', 'opacity:unset');
    }

    if (i) {
       const card = document.getElementById(i + 'card') as HTMLElement;
       card.setAttribute('class', 'card');
    }
    const card = this.myCard.nativeElement;
    card.setAttribute('class', 'card');
    this.videoPlaying = false;
  }


  getSongPlaying(e) {
    if (this.selectedProject.publishedUpcoming) {
      this.sendStatistic(e.project, e.value);
    }
  }

  sendStatistic(slugProject: string, key: string) {
    const type = {};
    type[key] = true;
    this.projectService.becomeUpcomingInvestor(slugProject, type).subscribe(resp => {
    });
  }

  iOSversion() {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      const v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
      const ver = [
        parseInt(v[1], 10),
        parseInt(v[2], 10),
        parseInt(v[3] || (0 as any), 10)];
      if (ver[0] < 15) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  checkDate(): void {
    this.endDate = this.item.projectEndDate ? new Date(this.item.projectEndDate).getTime() :
      new Date().setUTCHours(21, 59, 59, 999);
    const distance = this.calculateTimeDistance;
    const displayMinutes = this.displayMinutes;
    const displayHours = this.displayHours;
    const endDate = this.endDate;
    // Update the count down every 1 second
    const timer = setInterval(function() {
      // Time calculations for hours, minutes
      const hours = Math.floor((distance(endDate) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance(endDate) % (1000 * 60 * 60)) / (1000 * 60));

      const hoursName = hours >= 2 ? ' hours' : ' hour';
      const minutesName = minutes >= 2 ? ' minutes' : ' minute';

      displayHours.next(`${hours} ${hoursName}`);

      displayMinutes.next(`${minutes} ${minutesName}`);

      // If the count down is over, write some text
      if (distance(endDate) < 0) {
        clearInterval(timer);
      }
    }, 1000);
  }

  openSupplierProfile(project: any, e?: any, isNews?: boolean): void {
    if (!isNews) {
      e.preventDefault();
    }

    if (isPlatformBrowser(this.platformId)) {
      if ((!project.isUpcoming || project.status === 'upcoming') && !project.isNews && !project.infoCard && !this.isProject) {
        const name = e.target.className;
        const parentNodeHover = e.target.parentNode.className ? e.target.parentNode.className.indexOf('hover-block') >= 0 : null;
        if (name.indexOf('fas') && name.indexOf('play-button') && !parentNodeHover) {
          if (project.isCollectible && project.auctionMarket) {
            this.router.navigate([STATE_NAMES.collectible, project.slug, {auctionMarket: project.auctionMarket}]);
          } else {
            this.router.navigate([project.isCollectible ?
              STATE_NAMES.collectible : `/${STATE_NAMES.projectsItem}`, project.slug]);
          }
        }
      }
    }
  }

  getVideoFormat(url: string): string {
    if (!url) {
      return 'mp4';
    }
    return extractFileExtension(url);
  }

}
