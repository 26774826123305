import {AbstractStorage} from './abstract-storage.interface';

export const EMAIL_PATTERN = '^\\s*(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}' +
  '\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))\\s*$';
export const NUMBER_LENGTH_NINE = /^[0-9]{1,9}$/;
export const ONLY_NUM_LETTER_HYPEN = /^[\w\-]+$/;
export const NUMBER = /^[0-9]\d*$/;
export const PASSWORD_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export const PASSWORD_PATTERN_ANY = /^[A-Za-z0-9!"#$%&'()*+,-./:;<=>?@^_`{|}~]{0,32}$/;
export const NUMBER_POSITIVE_DOT_COMMA = /^[0-9]{1,4}([,.][0-9]{1,2})?$/;
export const NUMBER_POSITIVE = /^[1-9]\d*$/;
export const NUMBER_LENGTH_SIX = /^[0-9]{1,6}$/;
export const NUMBER_LENGTH_FOUR = /^[0-9]{1,4}$/;
export const NUMBER_POSITIVE_1000 = /^([1-9][0-9]{0,2}|1000)$/;
export const ALPHA_NUMERIC = /^[a-zA-Z0-9_]{1,7}$/;
export const ONLY_LETTER_AND_NUM = /^[a-zA-Z0-9]*$/;
export const FACEBOOK = /^((http(s?)):\/\/)?(www.)?facebook.com\/[a-zA-Z0-9_.-]*$/;
export const INSTAGRAM = /^((http(s?)):\/\/)?(www.)?instagram.com\/.*$/;
export const YOUTUBE = /^((http(s?)):\/\/)?(www.)?((youtube.com)|(youtu.be))?\/.*$/;
export const TWITTER = /^((http(s?)):\/\/)?(www.)?twitter.com\/.*$/;
export const SPOTIFY = /^((http(s?)):\/\/)?(www.)?open.spotify.com\/.*$/;
export const TIKTOK = /^((http(s?)):\/\/)?(www.)?tiktok.com\/.*$/;
export const SOUNDCLOUD = /^((http(s?)):\/\/)?(www.)?soundcloud.com\/.*$/;
export const DROPBOX = /^((http(s?)):\/\/)?(www.)?dropbox.com\/.*$/;
export const ONLY_LETTERS_FOR_NAME = /^[A-Za-zÀ-ȕ '"-]*$/;
export const ONLY_LETTERS = /^[a-zA-Z]*$/;
export const ONLY_LETTERS_WHITESPACE = /^[a-zA-Z\s]*$/;

export const EIT_NUM = /^\d{8}$/;
export const NIN_NUM = /^\d{9}$/;
export const TEN_NUM = /^\d{10}$/;
export const ELE_NUM = /^\d{11}$/;

export const NINE_AND_THIRT = /^((\d{9})|(\d{13}))$/;
export const EITH_AND_ELEVEN = /^((\d{8})|(\d{11}))$/;
export const NINE_AND_TWELVE = /^((\d{9})|(\d{12}))$/;
export const NINE_AND_ELEVEN = /^((\d{9})|(\d{11}))$/;
export const NINE_AND_FOUR = /^((\d{9})|(\d{14}))$/;
export const FIVE_AND_SIX = /^((\d{5})|(\d{6}))$/;
export const SIX_AND_EITH = /^((\d{6})|(\d{8}))$/;
export const THREE_TO_SIX_AND_NINE = /^((\d{3,6})|(\d{9}))$/;
export const SEVEN_TO_TEN = /^(\d{7,10})$/;
export const LL_SIX_L_OR_ONE_TO_SIX_L = /^(([a-zA-Z]{1,3}[0-9]{9})|(\d{1,6}[a-zA-Z])|([a-zA-Z]{1,3}[0-9]{6}[a-zA-Z]{1}))$/;
export const GERMANY_VALIDATION = /^(([a-zA-Z]{2,3}[0-9]{1,6})|([a-zA-Z]{2,3}[0-9]{1,6}[a-zA-Z])|([a-zA-Z]{2,3}[0-9]{1,6}[a-zA-Z]{1,2}))$/;
export const LIECHTENSTEIN = /^(([a-zA-Z]{3}[0-9]{3})\s+([0-9]{3})\s+([0-9]{3}))$/;
export const SWISS = /^(((OC)[0-9]{6})|(\d{8})|((SC)[0-9]{6})|((NI)[0-9]{6})|((R)[0-9]{7})|((IP)[0-9]{5}(R)))$/;
export const UNITED_KINGDOM = /^(((CHE)[0-9]{9})|(\d{9}))$/;
export const A_TO_J_AND_THREE = /^([a-jA-J][0-9]{3})$/;
export const LL_SIX = /^([a-zA-Z]{1,2}[0-9]{6})$/;
export const LL_TEN = /^([a-zA-Z]{2}[0-9]{10})$/;
export const L_FIVE = /^([a-zA-Z][0-9]{5})$/;
export const L_EITH_OR_L_SEVEN_L = /^(([a-zA-Z][0-9]{8})|([a-zA-Z][0-9]{7}[a-zA-Z]))$/;
export const ELEVEN_OR_LL_SEVEN = /^((\d{11})|([a-zA-Z]{2}[0-9]{7}))$/;

// Accounting patterns
export const IBAN_PATTERN = /^[a-zA-Z]{2}[0-9]{2}s*([a-zA-Z0-9_]{4}s*){2,7}[a-zA-Z0-9_]{1,4}s*$/;
export const BSB_PATTERN = /^\d{3}-?\d{3}$/;
export const BIC_PATTERN = /^[a-zA-Z]{6}[a-zA-Z0-9_]{2}([a-zA-Z0-9_]{3})?$/;
export const ABA_PATTERN = /^[0-9]{9}$/;
export const BRANCH_CODE_PATTERN = /^[0-9]{5}$/;
export const INSTITUTION_NUMBER_PATTERN = /^\d{3}$/;
export const ACCOUNTING_NUMBER_US = /^[0-9]+$/;
export const ACCOUNTING_NUMBER_CA = /^\d{7}$/;
export const ACCOUNTING_NUMBER_AU = /^[0-9]{6,10}$/;
export interface ConfigAlertPopup {
  text?: string;
  type?: string;
  buttonConfirm?: boolean;
  img?: string;
  nameButtonConfirm?: string;
  nameTypeConfirm?: string;
  buttonCancel?: boolean;
  nameButtonCancel?: string;
}

export interface IPagination {
  page: number;
  limit: number;
  offset: number;
}

export interface DictionaryItem<T = any> {
  [id: string]: T;
}

export interface IPagination {
  page: number;
  limit: number;
  offset: number;
}

export interface CookieServiceSetOptions {
  /**
   * expires
   */
  expires: Date;

  /**
   * Path
   */
  path: string;

  /**
   * Domain
   */
  domain: string;

  /**
   * Secure
   */
  secure: boolean;

  /**
   * Same site
   */
  sameSite: 'lax' | 'none' | 'strict';
}

/**
 * Cookie service
 * It's port ngx-cookie-service
 * @see https://github.com/stevermeister/ngx-cookie-service
 */
export abstract class CookieService {
  /**
   * @param name Cookie name
   */
  abstract check(name: string): boolean;

  /**
   * @param name Cookie name
   */
  abstract get(name: string): string;

  /**
   * Get all
   */
  abstract getAll(): { [key: string]: any };

  /**
   * @param name Cookie name
   * @param value Cookie value
   * @param options Options
   */
  abstract put(name: string, value: string, options: Partial<CookieServiceSetOptions>): void;

  /**
   * @param name Cookie name
   * @param path Cookie path
   * @param domain Cookie domain
   */
  abstract remove(name: string, path?: string, domain?: string): void;

  /**
   * @param path Cookie path
   * @param domain Cookie domain
   */
  abstract removeAll(path?: string, domain?: string): void;
}


export abstract class CookieStorage extends AbstractStorage {
  abstract setItem(key: string, value: string, options?: Partial<CookieServiceSetOptions>): void;
}


export interface TFA {
  secret: string;
  tempSecret: string;
  dataURL: string;
  tfaURL: string;
  enabled: boolean;
  verified: boolean;
}
