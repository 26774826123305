import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {SettingService} from './setting.service';
import {Dictionary} from '../models';
import {map, take, switchMap, mergeMap, filter, shareReplay, tap} from 'rxjs/operators';
import {StorageService} from './storage.service';

@Injectable({providedIn: 'root'})
export class DictionaryService {
  apiUrl: string;
  countries: Dictionary[];
  genres: Dictionary[];
  regions: any;
  genders: Dictionary[];
  languages: Dictionary[];
  private cachedCountries:BehaviorSubject<Dictionary[]> = new BehaviorSubject<Dictionary[]>(null);
  private onGetRegions = new BehaviorSubject<any>(null);
  private onGetGenders = new BehaviorSubject<any>(null);
  private onGetGenres = new BehaviorSubject<any>(null);
  private cachedDocuments$: Observable<any>;
  constructor(private http: HttpClient,
              private storage: StorageService,
              private setting: SettingService) {
    this.apiUrl = this.setting.apiUrl;
  }


  getCountries(): Observable<Dictionary[]> {
    if (!this.cachedCountries.getValue()) {
      return this.http.get(`${this.apiUrl}/dictionary/countries`).pipe(
        map((response: any) => response.map(item => new Dictionary().setFromApiModel(item))),
        shareReplay(1),
        tap((countries: Dictionary[])=>{
          return this.cachedCountries.next(countries);
        }),
      );
    }
    return this.cachedCountries.asObservable();
  }

  getCountryNames(countries:Dictionary[]): string [] {
    return countries.map(({ name }: Dictionary) => name);
  }

  getCountryCode(searchName: string): string | null {
    const countries: Dictionary [] = this.cachedCountries.getValue();

    return countries?.find(({ name }: Dictionary) => name === searchName)?.id;
  }

  getCountryName(searchCode: string | null): string | null {
    const countries: Dictionary [] = this.cachedCountries.getValue();

    return countries?.find(({ id }: Dictionary) => id === searchCode)?.name;
  }

  getDocumentLink(): Observable<any> {
    if (!this.cachedDocuments$) {
      this.cachedDocuments$ = this.http.get(`${this.apiUrl}/document`).pipe(
        map((response: any) => response),
        shareReplay(1)
      );
    }
    return this.cachedDocuments$;
  }

  getGenres(): Observable<Dictionary[]> {
    this._getData(this.genres, `/dictionary/genres`, this.onGetGenres);
    return this.onGetGenres.asObservable();
  }

  getGenders(): Observable<Dictionary[]> {

    this._getData(this.genders, `/dictionary/genders`, this.onGetGenders);

    return this.onGetGenders.asObservable();
  }

  getRegions(): Observable<any> {
    this.onGetRegions.pipe(take(1)).subscribe(item => {
      if (item) {
        this.onGetRegions.next(item);
      } else {
        this._getData(this.countries, `/dictionary/regions`, this.onGetRegions, 'regions');
      }
    });
    return this.onGetRegions.asObservable();


    // this._getData(this.regions, `/dictionary/regions`, this.onGetRegions, 'regions');
    // return this.onGetRegions.asObservable();
  }

  typeDocument(doc): string {
    const user = this.storage.user;
    const language = user && user.language ? user.language : 'en';
    return doc + language.charAt(0).toUpperCase() + language.charAt(1);
  }

  private _getData(list, url, subject, type?: string) {
    if (list) {
      setTimeout(() => {
        subject.next(list);
      }, 4);
    } else {
      this.http.get(`${this.apiUrl}${url}`).subscribe((resp: any) => {
        if (type === 'regions') {
          list = {
            US: resp.US.map(i => ({id: i, name: i})),
            MX: resp.MX.map(i => ({id: i, name: i})),
            CA: resp.CA.map(i => ({id: i, name: i}))
          };
        } else {
          list = resp.map(item => new Dictionary().setFromApiModel(item));
        }
        subject.next(list);
      });
    }
  }
}


