<div class="form-wrapper" [ngClass]="styleType">
  <div class="summary min-sreen" *ngIf="!isNewAccount">
    <ul class="summary__content">
      <li class="summary__switch">
        <div class="color-light-grey xs-toggle toggle">
          <label class="form__toggle mobile"
            ><span>Primary</span>
            <mat-slide-toggle
              (change)="setAsPrimary()"
              [disabled]="isNewAccount || account?.isPreferred"
              [checked]="!account?.isPreferred"
            ></mat-slide-toggle>
          </label>
        </div>
        <span class="title18 font-bold"> {{ account.placeholder }}</span>
      </li>
      <li *ngIf="form.controls.IBAN">IBAN: {{ account.IBAN }}</li>
      <li *ngIf="form.controls.BSB">BSB: {{ account.BSB }}</li>
      <li *ngIf="form.controls.BIC">BIC: {{ account.BIC }}</li>
      <li *ngIf="form.controls.ABA">ABA: {{ account.ABA }}</li>
      <li *ngIf="form.controls.AccountNumber">
        Account Number: {{ account.AccountNumber }}
      </li>
    </ul>
    <div class="summary__action">
      <i class="fas fa-pen" (click)="editAccount()"></i>
      <i class="fas fa-trash" (click)="deleteAccount()"></i>
    </div>
  </div>
  <form
    class="form"
    [formGroup]="form"
    [class.disabled]="form.disabled"
    [class.new]="isNewAccount"
  >
    <div class="color-light-grey xs-toggle toggle desktop">
      <label class="form__toggle"
        ><span>Primary</span>
        <mat-slide-toggle
          (change)="setAsPrimary()"
          [disabled]="isNewAccount || account?.isPreferred"
          [checked]="!account.isPreferred"
        ></mat-slide-toggle>
      </label>
    </div>
    <mat-form-field class="form__control" *ngIf="form.controls.placeholder">
      <mat-label>Account Name</mat-label>
      <input matInput formControlName="placeholder" type="text" disabled />
    </mat-form-field>
    <mat-form-field class="form__control" *ngIf="form.controls.IBAN">
      <mat-label>IBAN</mat-label>
      <input matInput formControlName="IBAN" type="text" />
      <mat-error *ngIf="form.controls.IBAN.errors?.required"
        >This field is required</mat-error
      >
      <mat-error
        *ngIf="
          form.controls.IBAN.errors?.IBAN || form.controls.IBAN.errors?.pattern
        "
        >Invalid value</mat-error
      >
    </mat-form-field>
    <mat-form-field class="form__control" *ngIf="form.controls.BSB">
      <mat-label>BSB</mat-label>
      <input matInput formControlName="BSB" type="text" />
      <mat-error *ngIf="form.controls.BSB.errors?.required"
        >This field is required</mat-error
      >
      <mat-error *ngIf="form.controls.BSB.errors?.pattern"
        >Invalid value</mat-error
      >
    </mat-form-field>
    <mat-form-field class="form__control--small" *ngIf="form.controls.BIC">
      <mat-label>BIC</mat-label>
      <input matInput formControlName="BIC" type="text" />
      <mat-error *ngIf="form.controls.BIC.errors?.required"
        >This field is required</mat-error
      >
      <mat-error *ngIf="form.controls.BIC.errors?.pattern"
        >Invalid value</mat-error
      >
    </mat-form-field>
    <mat-form-field class="form__control" *ngIf="form.controls.ABA">
      <mat-label>ABA</mat-label>
      <input matInput formControlName="ABA" type="text" />
      <mat-error *ngIf="form.controls.ABA.errors?.required"
        >This field is required</mat-error
      >
      <mat-error *ngIf="form.controls.ABA.errors?.pattern"
        >Invalid value</mat-error
      >
    </mat-form-field>
    <mat-form-field class="form__control" *ngIf="form.controls.BankName">
      <mat-label>Bank Name</mat-label>
      <input matInput formControlName="BankName" type="text" />
      <mat-error *ngIf="form.controls.BankName.errors?.required"
        >This field is required</mat-error
      >
    </mat-form-field>
    <mat-form-field
      class="form__control"
      *ngIf="form.controls.DepositAccountType"
    >
      <mat-label>Account Type</mat-label>
      <mat-select formControlName="DepositAccountType">
        <mat-option *ngFor="let item of depositAccountTypes" [value]="item">{{
          item
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="form__control" *ngIf="form.controls.BranchCode">
      <mat-label>Branch Code</mat-label>
      <input matInput formControlName="BranchCode" type="text" />
      <mat-error *ngIf="form.controls.BranchCode.errors?.required"
        >This field is required</mat-error
      >
      <mat-error *ngIf="form.controls.BranchCode.errors?.pattern"
        >Invalid value</mat-error
      >
    </mat-form-field>
    <mat-form-field
      class="form__control"
      *ngIf="form.controls.InstitutionNumber"
    >
      <mat-label>Institution Number</mat-label>
      <input matInput formControlName="InstitutionNumber" type="text" />
      <mat-error *ngIf="form.controls.InstitutionNumber.errors?.required"
        >This field is required</mat-error
      >
      <mat-error *ngIf="form.controls.InstitutionNumber.errors?.pattern"
        >Invalid value</mat-error
      >
    </mat-form-field>
    <mat-form-field class="form__control" *ngIf="form.controls.AccountNumber">
      <mat-label>Account Number</mat-label>
      <input matInput formControlName="AccountNumber" type="text" />
      <mat-error *ngIf="form.controls.AccountNumber.errors?.required"
        >This field is required</mat-error
      >
      <mat-error *ngIf="form.controls.AccountNumber.errors?.pattern"
        >Invalid value</mat-error
      >
    </mat-form-field>
    <mat-error *ngIf="apiError">{{ apiError }}</mat-error>
  </form>
  <div class="button__wrapper">
    <ng-container *ngIf="form.disabled; else createNewActions">
      <span
        class="button--edit text-uppercase link-blue"
        (click)="editAccount()"
        >Edit</span
      >
      <span
        class="button--delete text-uppercase link-red"
        (click)="deleteAccount()"
        >Delete</span
      >
    </ng-container>
    <ng-template #createNewActions="">
      <span
        class="button--save text-uppercase link-blue"
        (click)="saveAccount()"
        >Save</span
      >
      <span
        class="button--save-mobile grs-button--orange"
        (click)="saveAccount()"
        >Save</span
      >
      <span
        class="button--cancel text-uppercase link-red"
        (click)="cancelChanges()"
        >Cancel</span
      >
    </ng-template>
  </div>
</div>
