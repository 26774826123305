import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'tenth' })
export class TenthPipe implements PipeTransform {
  transform(value: number | string): number | string {
    if (typeof value === 'number') return (value / 10).toFixed(2);

    return this.formatString(value);
  }

  private formatString(price: string): string {
    const numericPrice: number = Number(
      price.replace('€', '').replace('$', '').replace(',', '')
    );

    if (typeof numericPrice !== 'number') return price;

    if (price.includes('€')) {
      return `${(numericPrice / 10).toFixed(2)} €`;
    } else if (price.includes('$')) {
      return `$${(numericPrice / 10).toFixed(2)}`;
    }

    return (numericPrice / 10).toFixed(2);
  }
}
