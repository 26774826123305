export const CARD_CONTENT: any = {
  grs: {
    logo: './assets/images/logo/grs-small-logo.png',
    icon: './assets/images/cards/logo-white.svg',
    t_text: 'Global Rockstar Artists are directly signed to the Global Rockstar\n' +
      '              Music Label and/or Publishing and supported in longterm contracts\n' +
      '              by the company.',
  },
  collectible: {
    logo: './assets/images/logo/grs-small-logo.png',
    icon: './assets/images/cards/diamond.svg',
    t_text: 'Collectibles are unreleased and never to be released recordings by\n' +
      '              superstars or established artists. Owners have the exclusive right\n' +
      '              to play the recording in full-length from their Global Rockstar\n' +
      '              dashboard, whenever they want and will be able to trade the\n' +
      '              collectibles on the Global Rockstar marketplace or on other\n' +
      '              platforms in the near future.',
  },
  diy: {
    logo: './assets/images/logo/independent.png',
    icon: './assets/images/cards/fire.svg',
    t_text: 'Independent Artists use the Global Rockstar platform to non-exclusively fund, distribute & ' +
      'promote their music with the support of the Global Rockstar Label &/or Publishing.',
    link: true
  }
}
