import {Component, OnDestroy, OnInit, OnChanges, Input, Output, EventEmitter} from '@angular/core';
import {SettingService} from 'app/services';

import {STATE_NAMES} from '../../common';
import {StreamState} from '../../models/stream-state.model';
import {AudioService} from '../../services/audio.service';
import {Router} from '@angular/router';
import {ProjectsService} from '../../services/projects.service';


@Component({
  selector: 'app-song-preview',
  templateUrl: './song-preview.html'
})
export class SongPreviewComponent implements OnInit, OnDestroy, OnChanges {
  state: StreamState;
  audioServiceSubscription: any;
  host: string;
  wouldInvestActive: boolean;
  @Input() project: any;
  @Input() wouldInvestActiveChange: boolean;
  @Input() user: any;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  constructor(private audioService: AudioService,
              private router: Router,
              private projectService: ProjectsService,
              private settingService: SettingService) {
    this.host = settingService.imgUrl;
  }

  ngOnInit() {
    this.audioServiceSubscription = this.audioService.getState().subscribe(state => {
      this.state = state;
    });

  }

  ngOnChanges(data) {
    if (data.project && data.project.currentValue) {
      this.playStream(this.project.soundtrack);
      if (((this.project.isUpcoming && this.project.status === 'upcoming') || this.project?.activeUpcoming) && this.user) {
        this.notify.emit({type: 'stat', project: this.project.slug, value: 'playMusic'});
        this.wouldInvestActive = this.project.statistic && this.project.statistic.wouldInvest &&
          this.project.statistic.wouldInvest.indexOf(this.user.slug) >= 0;
      }
    }
  }

  ngOnDestroy() {
    if (this.audioServiceSubscription) {
      this.audioServiceSubscription.unsubscribe();
    }
    this.audioService.stop();
  }

  playStream(url) {
    this.audioService.stop();
    this.audioService.playStream(`${this.host}/${url}`).subscribe((events: any) => {
      if (events.type === 'playing') {
        this.project.songIsPlaying = true;
      } else if (events.type === 'error') {
        this.project.songIsPlaying = false;
      }
    });
  }

  muteSound(){
    this.audioService.mute();
  }

  updateVolume(event){
    this.audioService.volumeChange(event);
  }

  togglePlayer() {
    if (this.state && !this.state.error) {
      if (this.state.playing) {
        this.audioService.stop();
        this.project.songIsPlaying = false;
        this.notify.emit('stop');
      } else {
        this.audioService.play();
      }
    }
  }

  videoTiming(event){
    const skipTo = Math.round((event.offsetX / event.target.clientWidth) * parseInt(event.target.getAttribute('max'), 10));
    this.audioService.seekTo(skipTo);
   }

  wouldInvestF(key: string) {
    this.notify.emit({type: 'stat', value: key, project: this.project.slug});
    this.wouldInvestActive = true;
  }

  goToCalculationPage(isValid?: boolean) {
    if (!isValid) {
      if (this.user) {
        this.router.navigate([`/${STATE_NAMES.calculate}`, this.project.slug]);
        if (!this.user.verified) {
          this.projectService.verifyPopup(true);
        }
      } else {
        this.router.navigate([`/${STATE_NAMES.login}`], {
          state: {
            slug: this.project.slug,
            params: {slug: this.project.slug},
            state: STATE_NAMES.calculate
          }
        });
      }
    }
  }
}
