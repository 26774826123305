export class ArtistModel {
  constructor(props) {
    this.imgUrl = props;
  }

  imgUrl: string;
  id: number;
  name: string;
  type: string;
  contact: any;
  country: string;
  image: string;
 // fans: number;
  slug: string;
  countryCode: string;
  social: any;

  setFromApiModel(apiModel: any): this {
    this.id = apiModel._id;
    this.name = apiModel.name;
    this.type = apiModel.__t;
    this.image = apiModel.image ? `${this.imgUrl}/images/${apiModel.image}` : null;
   // this.fans = apiModel.fans;
    this.country = apiModel.country;
    this.countryCode = apiModel.countryCode;
    this.slug = apiModel.slug;
    this.contact = apiModel.contact;
    this.social = apiModel.social;
    return this;
  }
}
