export const environment = {
  production: false,
  apiUrl: 'https://development.globalrockstar.com/server',
  imgUrl: 'https://dvkkk6z0cvz0q.cloudfront.net',
  facebookId: '489079268542757',
  gaEnabled: false,
  fbEnabled: false,
  ourLabelSlug: 'our-label',
  appleClientId: 'com.globalrockstar.development.auth.client',
  appleRedirectURL: 'https://development.globalrockstar.com/server/apple-login',
  microsoftViewFileAPI: 'https://view.officeapps.live.com/op/embed.aspx?src=',
  nftRedirectSlug: 'nft-redirect',
  magicKey: 'pk_live_917B422B6558B5A3',
  merchantId: '12345678901234567890',
  merchantName: 'Demo Merchant',
  googlePay: 'TEST',
  lock: false,
  ipregistryKey: 'd01cnowhjsyit7hd',
  idealIxUrl: 'https://beta.idealidx.com',
  idealixParentWindowDomain: 'https://beta.idealidx.com',
};
