import {Injectable} from '@angular/core';

import {SettingService} from './setting.service';
import {Observable} from 'rxjs';

import {RequestModel} from '../models';

import {map} from 'rxjs/operators';
import {ApiService} from "@services/api.service";
import {Investments, IRevenues} from "@app/interfaces/revenue.interface";

interface IRevenueResponse {
  list: Investments[];
  total: number;
}

@Injectable({providedIn: 'root'})

export class ReleaseService {
  apiUrl: string;

  constructor(private apiService: ApiService,
              private setting: SettingService) {
    this.apiUrl = this.setting.apiUrl;
  }

  getReleases(request: RequestModel): Observable<any> {
    const params = request.getApiModel();

    const endpoint = `${this.apiUrl}/investments`;
    return this.apiService.get<IRevenueResponse>(endpoint, params)
      .pipe(
        map((resp: IRevenueResponse) => {
          if (resp) {
            return {
              investments: resp.list,
              total: resp.total,
            };
          }
        }));
  }
}
