import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {WizardService} from '@services/wizard.service';
import {StorageService} from "@services/storage.service";
import {IInvestParam} from "@app/interfaces/payment.interface";

@Component({
  selector: 'app-base-select-amount',
  templateUrl: './base-select-amount.html',
  styleUrls: ['./base-select-amount.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseSelectAmountComponent implements OnInit {
  share: any;
  config = {
    displayTitle: true
  };
  slug: string;
  shares: any[] = [];
  payAmount = 0;
  youtubeFollowers: number;
  selectedShare: number;
  height: number;
  submitBtn: boolean;
  bidAmount: number;
  menuActive: boolean;
  showPopup: boolean;
  maxPercent: number;
  baseAmount: number;
  innerProject: any;
  firstDownload = true;
  originAmount: number;
  priceForShare: number;
  emailCtrl: FormControl;
  form: FormGroup;
  @Input() project: any;
  @Input() user: any;
  @Output() selectedAmount: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('document:click', ['$event']) onClick(event) {
    const allowedClasses = 'item-dropdown';
    const parentNode = event.target.parentNode ? event.target.parentNode.className : '';
    if (parentNode && typeof parentNode === 'string' && parentNode.indexOf(allowedClasses) >= 0) {
      return true;
    } else if (this.menuActive) {
      this.menuActive = false;
    }
  }

  constructor(private wizardService: WizardService,  private storageService: StorageService) {
    this.share = 1;
    this.firstDownload = true;
  }

  ngOnInit() {
    this.innerProject = JSON.parse(JSON.stringify(this.project));
    const project = this.project;
    this.slug = this.project.slug;
    const sale = this.project.saleAmount;
    this.priceForShare = this.wizardService.priceForShare(this.project.priceForShare);
    this.baseAmount = sale ? this.priceForShare - (this.priceForShare * (sale / 100)) :  this.priceForShare;

    if (project.totalInvestorsLeft > 0) {
      this.shares.push({
        value: 0.1,
        amount: this.baseAmount * 0.1
      });
    }
    if (project.totalInvestorsLeft >= 0.2) {
      this.shares.push({
        value: 0.2,
        amount: this.baseAmount * 0.2
      });
    }
    if (project.totalInvestorsLeft >= 0.5) {
      this.shares.push({
        value: 0.5,
        amount: this.baseAmount * 0.5
      });
    }
    if (project.totalInvestorsLeft >= 1) {

      this.shares = [...this.shares, ...[...Array(Math.floor(project.totalInvestorsLeft)).keys()].map(x => {
        const val = ++x;
        return {
          value: val,
          amount: this.baseAmount * val,
          disabled: (this.baseAmount * val) > 2500
        };
      })];
    }
    this.share = 0.1;
    const left = project.totalInvestorsLeft * this.baseAmount;
    if (left <= 2000) {
      this.shares.push({
        amount: left,
        value: 'all',
        disabled: false
      });

      // this.totalShareMax = project.totalInvestorsLeft;
      this.maxPercent = left;
    }
    if (project.totalInvestorsLeft < 1 && project.totalInvestorsLeft >= 0.1) {
      this.share = 0.1;
    } else if (!project.totalInvestorsLeft) {
      this.share = 0;
    }
    this.height = this.shares.length * 10;
    this.handleSelectAmount();
  }

  toggleMenu(item?) {
    this.menuActive = true;
    this.share = null;
  }

  selectShare(item) {
    this.menuActive = false;
    this.selectedShare = item.value;
    this.onChangeItem(item.value);
  }

  setShare(value: number) {
    this.selectedShare = null;
    const percentAmount = this.baseAmount * value;
    if (value <= this.project.totalInvestorsLeft && percentAmount <= 2500) {
      this.handleSelectAmount(value);
    }
  }

  onChangeItem(value) {
    this.handleSelectAmount(value);
    if ([0.1, 0.2, 1, 5].indexOf(value) >= 0) {
      this.selectedShare = null;
    }
  }

  handleSelectAmount(value?) {
    if (value) {
      this.share = value !== 'all' ? value : 'all';
    }
    const totalInvestorsLeft = this.innerProject.totalInvestorsLeft;

    let shareToCalculate = this.share !== 'all' ? this.share : totalInvestorsLeft;

    this.payAmount = this.wizardService.getSaleAndOriginAmount(shareToCalculate, this.baseAmount);
    this.originAmount = this.wizardService.getSaleAndOriginAmount(shareToCalculate,  this.priceForShare);

    const DATA: IInvestParam = {
      payAmount: Math.round(100 * this.payAmount) / 100,
      originAmount: Math.round(100 * this.originAmount) / 100,
      share: this.share,
      project_slug: this.project.slug,
      salePercent: this.project.saleAmount
    };

    this.wizardService.setInvestDetail(DATA);
    this.selectedAmount.emit(DATA);
  }

}

