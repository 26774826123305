import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from 'environments/environment';
import { ProfileService } from '@services/profile.service';
import { isPlatformBrowser } from '@angular/common';
import { Observable, from } from 'rxjs';
import { VerifyUser } from '@app/interfaces/verify-email.interface';
import { RoutingService } from '@services/routing.service';
import { encodeStringUri } from '@app/common/helpers';
import { StorageService } from './storage.service';

declare let Magic: any;

@Injectable()
export class MagicAuthService {
  magic: any;

  constructor(
    private userService: ProfileService,
    private routingService: RoutingService,
    private storage: StorageService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.magic = new Magic(environment.magicKey);
  }

  getConfig(): any {
    return this.magic;
  }

  generateWallet(email?: string): void {
    if (isPlatformBrowser(this.platformId)) {
      const previousPage = this.routingService.getPreviousUrl();
      let withoutFirstLetter = encodeStringUri(previousPage.substring(1));
      const user = this.userService.syncGetUser();
      const user_email = user?.contact?.email ?? email;
      const redirectURI =
        encodeURI(`${window.location.origin}/api/verify/`) +
        (withoutFirstLetter || 'opportunity') +
        encodeURI(`?grstoken=${this.storage.accessToken}`);
      this.connectToMagicLink(redirectURI, user_email);
    }
  }

  generateWalletWithOTP(): Observable<string> {
    const user = this.userService.syncGetUser();
    const user_email = user?.contact?.email;

    return this.magicOTP(user_email);
  }

  private async connectToMagicLink(redirectURI: string, email: string) {
    const magic = this.getConfig();
    try {
      await magic.auth.loginWithMagicLink({
        email: email,
        redirectURI: redirectURI,
      });
    } catch (error) {
      console.error('Login failed:', error);
    }
  }

  private magicOTP(email: string): Observable<string> {
    const magic = this.getConfig();

    return from(magic.auth.loginWithEmailOTP({ email })) as Observable<string>;
  }

  sendDIDToken(DID: string): Observable<VerifyUser> {
    return this.userService._saveWalletNFT(DID);
  }
}
