<div class="top-wrapper">
  <div *ngIf="mobile" class="item social mobile-social">
    <a
      href="https://twitter.com/Global_Rockstar"
      target="_blank"
      class="footer-social"
    >
      <img
        class="project-highlighted__logo"
        loading="lazy"
        alt="globalrockstar twitter"
        title="GlobalRockstar twitter"
        src="./assets/images/social/twitter-footer.png"
      />
    </a>
    <a
      href="https://www.facebook.com/TheGlobalRockstar"
      target="_blank"
      class="footer-social footer-social--left"
    >
      <img
        class="project-highlighted__logo"
        loading="lazy"
        alt="globalrockstar facebook"
        title="GlobalRockstar facebook"
        src="./assets/images/social/facebook-footer.png"
      />
    </a>
    <a
      href="https://www.youtube.com/user/TheGlobalRockstar"
      target="_blank"
      class="footer-social footer-social--left"
    >
      <img
        class="project-highlighted__logo"
        loading="lazy"
        alt="globalrockstar youtube"
        title="GlobalRockstar youtube"
        src="./assets/images/social/youtube-footer.png"
      />
    </a>
    <a
      href="https://www.instagram.com/globalrockstarmusic/?hl=de"
      target="_blank"
      class="footer-social footer-social--left"
    >
      <img
        class="project-highlighted__logo"
        loading="lazy"
        alt="globalrockstar instagram"
        title="GlobalRockstar instagram"
        src="./assets/images/social/instagram-footer.png"
      />
    </a>
    <a
      href="https://at.linkedin.com/company/global-rockstar-gmbh"
      target="_blank"
      class="footer-social footer-social--left"
    >
      <img
        class="project-highlighted__logo"
        loading="lazy"
        alt="globalrockstar linkedin"
        title="GlobalRockstar linkedin"
        src="./assets/images/social/linkedin-footer.png"
      />
    </a>
    <a
      href="https://www.tiktok.com/@globalrockstar"
      target="_blank"
      class="footer-social footer-social--left"
    >
      <img
        class="project-highlighted__logo"
        loading="lazy"
        alt="globalrockstar tiktok"
        title="GlobalRockstar tiktok"
        src="./assets/images/social/tiktok-footer.png"
      />
    </a>
    <!-- <a href="https://t.me/+SucxFTP-eZ4xYjZk" target="_blank"  class="footer-social footer-social--left">
      <img class="project-highlighted__logo"
           loading="lazy" alt="globalrockstar telegram" title="GlobalRockstar telegram" src="./assets/images/social/telegram-footer.png">
    </a>
    <a href="https://discord.gg/es3JeKRAaJ" target="_blank" class="footer-social footer-social--left">
      <img class="project-highlighted__logo"
           loading="lazy" alt="globalrockstar discord" title="GlobalRockstar discord" src="./assets/images/social/discord-footer.png">
    </a> -->
  </div>
  <div class="item web">
    <div class="title">About</div>
    <div class="links">
      <a
        (click)="navigateTo('how-it-works', $event)"
        class="nowrap"
        href="https://www.globalrockstar.com/how-it-works"
        >How it works</a
      >
      <span class="spacer">|</span>
      <a
        (click)="navigateTo('api/voucher', $event)"
        href="https://www.globalrockstar.com/api/voucher"
        >Vouchers</a
      >
      <span class="spacer">|</span>
      <a
        class="nowrap"
        href="https://forms.gle/yG81xzHeYp7T2HoR6"
        target="_blank"
        >Submit song</a
      >
      <span class="spacer">|</span>
      <a href="mailto:office@globalrockstar.com">Contact</a>
      <span class="spacer">|</span>
      <a
        (click)="navigateTo('about-grs', $event)"
        href="https://www.globalrockstar.com/about-grs"
        class="nowrap"
        >About us</a
      >
    </div>
  </div>
  <div class="item web legal">
    <div class="title">Legal</div>
    <div class="links">
      <a
        [grsOpenFile]="'terms'"
        href="https://d1mfwo6pcdbmd.cloudfront.net/documents/1683560339366-NUTZUNGSBEDINGUNGEN---TERM-OF-USE-German-English-Update_-May-23-2.pdf"
        >Terms</a
      >
      <span class="spacer">|</span>
      <a
        (click)="navigateTo('api/imprint', $event)"
        href="https://www.globalrockstar.com/api/imprint"
        >Imprint</a
      >
      <span class="spacer">|</span>
      <a
        [grsOpenFile]="'mangopay'"
        target="_blank"
        href="https://d1mfwo6pcdbmd.cloudfront.net/documents/1658136994533-Mangopay_Terms-EN.pdf"
        >Mangopay</a
      >
      <span class="spacer">|</span>
      <a
        [grsOpenFile]="'dataPolicy'"
        href="https://d1mfwo6pcdbmd.cloudfront.net/documents/1658137131511-Data_and_privacy_policy.pdf"
        >Data policy</a
      >
    </div>
  </div>
  <div class="mobile-rotation">
    <div class="item">
      <div class="title">About</div>
      <div class="links">
        <a
          (click)="navigateTo('how-it-works', $event)"
          class="nowrap"
          href="https://www.globalrockstar.com/how-it-works"
          >How it works</a
        >
        <span class="spacer">|</span>
        <a
          (click)="navigateTo('api/voucher', $event)"
          href="https://www.globalrockstar.com/api/voucher"
          >Vouchers</a
        >
        <span class="spacer">|</span>
        <a
          class="nowrap"
          href="https://docs.google.com/forms/d/e/1FAIpQLSfMUOdmRxWYP_qIylXi95wskYmdPj6C6CACW7A8mTMblsqc6A/viewform"
          target="_blank"
          >Submit song</a
        >
        <span class="spacer">|</span>
        <a href="mailto:office@globalrockstar.com">Contact</a>
        <span class="spacer">|</span>
        <a
          (click)="navigateTo('about-grs', $event)"
          href="https://www.globalrockstar.com/about-grs"
          class="nowrap"
          >About us</a
        >
      </div>
    </div>
    <div class="item">
      <div class="title">Legal</div>
      <div class="links">
        <a
          [grsOpenFile]="'terms'"
          href="https://d1mfwo6pcdbmd.cloudfront.net/documents/1683560339366-NUTZUNGSBEDINGUNGEN---TERM-OF-USE-German-English-Update_-May-23-2.pdf"
          >Terms</a
        >
        <span class="spacer">|</span>
        <a
          (click)="navigateTo('api/imprint', $event)"
          href="https://www.globalrockstar.com/api/imprint"
          >Imprint</a
        >
        <span class="spacer">|</span>
        <a
          [grsOpenFile]="'mangopay'"
          target="_blank"
          href="https://d1mfwo6pcdbmd.cloudfront.net/documents/1658136994533-Mangopay_Terms-EN.pdf"
          >Mangopay</a
        >
        <span class="spacer">|</span>
        <a
          [grsOpenFile]="'dataPolicy'"
          href="https://d1mfwo6pcdbmd.cloudfront.net/documents/1658137131511-Data_and_privacy_policy.pdf"
          >Data policy</a
        >
      </div>
    </div>
  </div>
  <div class="item social web-social">
    <a
      href="https://twitter.com/Global_Rockstar"
      target="_blank"
      class="footer-social"
    >
      <img
        class="project-highlighted__logo"
        loading="lazy"
        alt="globalrockstar twitter"
        title="GlobalRockstar twitter"
        src="./assets/images/social/twitter-footer.png"
      />
    </a>
    <a
      href="https://www.facebook.com/TheGlobalRockstar"
      target="_blank"
      class="footer-social footer-social--left"
    >
      <img
        class="project-highlighted__logo"
        loading="lazy"
        alt="globalrockstar facebook"
        title="GlobalRockstar facebook"
        src="./assets/images/social/facebook-footer.png"
      />
    </a>
    <a
      href="https://www.youtube.com/user/TheGlobalRockstar"
      target="_blank"
      class="footer-social footer-social--left"
    >
      <img
        class="project-highlighted__logo"
        loading="lazy"
        alt="globalrockstar youtube"
        title="GlobalRockstar youtube"
        src="./assets/images/social/youtube-footer.png"
      />
    </a>
    <a
      href="https://www.instagram.com/globalrockstarmusic/?hl=de"
      target="_blank"
      class="footer-social footer-social--left"
    >
      <img
        class="project-highlighted__logo"
        loading="lazy"
        alt="globalrockstar instagram"
        title="GlobalRockstar instagram"
        src="./assets/images/social/instagram-footer.png"
      />
    </a>
    <a
      href="https://at.linkedin.com/company/global-rockstar-gmbh"
      target="_blank"
      class="footer-social footer-social--left"
    >
      <img
        class="project-highlighted__logo"
        loading="lazy"
        alt="globalrockstar linkedin"
        title="GlobalRockstar linkedin"
        src="./assets/images/social/linkedin-footer.png"
      />
    </a>
    <a
      href="https://www.tiktok.com/@globalrockstar"
      target="_blank"
      class="footer-social footer-social--left"
    >
      <img
        class="project-highlighted__logo"
        loading="lazy"
        alt="globalrockstar tiktok"
        title="GlobalRockstar tiktok"
        src="./assets/images/social/tiktok-footer.png"
      />
    </a>
    <!-- <a href="https://t.me/+SucxFTP-eZ4xYjZk" target="_blank"  class="footer-social footer-social--left">
      <img class="project-highlighted__logo"
           loading="lazy" alt="globalrockstar telegram" title="GlobalRockstar telegram" src="./assets/images/social/telegram-footer.png">
    </a>
    <a href="https://discord.gg/es3JeKRAaJ" target="_blank" class="footer-social footer-social--left">
      <img class="project-highlighted__logo"
           loading="lazy" alt="globalrockstar discord" title="GlobalRockstar discord" src="./assets/images/social/discord-footer.png">
    </a> -->
  </div>
</div>
<div class="bottom-wrapper web-footer">
  <div class="flex-align info-footer">
    <div class="img-wrapper">
      <img
        src="assets/images/grs_logo_white.png"
        alt="globalrockstar logo"
        (click)="navigateTo('app.home')"
        class="logo"
      />
    </div>
    <div class="copy">
      Copyright © {{ globalTime }} Global Rockstar<br />All rights reserved.
      Worldwide.
    </div>
    <div class="phone-number">
      <span>Support hotline:&nbsp;</span>
      <a href="tel:+436603243801">+43 660 324 38 01</a>
    </div>
  </div>
  <div class="img-wrapper-payment">
    <div class="payment-wrapper">
      <div class="img-wrapper-image">
        <img
          src="assets/images/footer/visamastercard.webp"
          alt="visamastercard"
          loading="lazy"
          title="VISA/MASTERCARD"
          class="footer-payment"
        />
      </div>
      <div class="img-wrapper--small">
        <img
          src="assets/images/footer/Klarna.png"
          alt="klarna"
          loading="lazy"
          title="klarna"
          class="footer-payment"
        />
      </div>
      <div class="img-wrapper--small">
        <img
          src="assets/images/footer/google-pay-logo-black-white.webp"
          alt="Goole Pay"
          loading="lazy"
          title="Google Pay"
          class="footer-payment icon-g-pay"
        />
      </div>
    </div>
    <img
      src="assets/images/footer/powered.webp"
      alt="powered"
      loading="lazy"
      title="powered"
      class="powered"
    />
  </div>
</div>
<div class="bottom-wrapper mobile-footer">
  <div class="flex-align info-footer">
    <div class="img-wrapper">
      <img
        src="assets/images/grs_logo_white.png"
        alt="logo globalrockstar"
        loading="lazy"
        title="footer logo globalrockstar"
        (click)="navigateTo('app.home')"
        class="logo"
      />
    </div>
    <div class="phone-number text-uppercase">
      <span>Support hotline:</span>
      <a href="tel:+436603243801">+43 660 324 38 01</a>
    </div>
    <div class="copy">
      Copyright © {{ globalTime }} Global Rockstar<br />All rights reserved.
      Worldwide.
    </div>
  </div>
  <div class="img-wrapper-payment">
    <div class="payment-wrapper">
      <img
        src="assets/images/footer/visa-mobile.png"
        alt="visa"
        loading="lazy"
        title="VISA"
        class="footer-payment"
      />
      <img
        src="assets/images/footer/mastercard-mobile.png"
        alt="mastercard"
        loading="lazy"
        title="mastercard"
        class="footer-payment"
      />
      <img
        src="assets/images/footer/Klarna.png"
        alt="klarna"
        loading="lazy"
        title="klarna"
        class="footer-payment"
      />
      <img
        src="assets/images/footer/google-pay-logo-black-white.webp"
        alt="Goole Pay"
        loading="lazy"
        title="Google Pay"
        class="footer-payment"
      />
    </div>
    <img
      src="assets/images/footer/powered.webp"
      alt="powered"
      loading="lazy"
      title="powered"
      class="powered"
    />
  </div>
</div>
