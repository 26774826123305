<div class="select-share">
  <div class="item" [class.active]="share === 0.1" [class.disabled]="project?.totalInvestorsLeft < 0.1"
       (click)="setShare(0.1)">
    0.1%
  </div>
  <div class="item" [class.active]="share === 1" [class.disabled]="project?.totalInvestorsLeft < 1"
       (click)="setShare(1)">
    1%
  </div>
  <div class="item" [class.active]="share === 5"
       [class.disabled]="project?.totalInvestorsLeft < 5 || baseAmount * 5 > 2500"
       (click)="setShare(5)">
    5%
  </div>
  <div class="item item-dropdown"
       [class.active]="menuActive || selectedShare"
       [class.disabled]="project?.totalInvestorsLeft === 0"
       (click)="toggleMenu(project)">
    <div class="empty" *ngIf="!selectedShare">Choose</div>
    <div class="selected" *ngIf="selectedShare">{{selectedShare === 'all' ? 'all' : selectedShare + '%'}}</div>
    <i class="fas fa-chevron-down"></i>
  </div>
  <div class="menu" [class.active]="menuActive">
    <div *ngFor="let item of shares"
         (click)="selectShare(item)"
         class="menu-item">
      {{item.value === 'all' ? 'All shares': item.value}}
      {{item.value === 'all' ? '' : '%'}}
    </div>
  </div>
</div>

<div class="amount">
  <p [class.error-message]="project?.saleAmount">{{payAmount | localeCurrency}}</p>
  <p class="origin-amount" *ngIf="project?.saleAmount">Original Price:
    <span class="origin-amount-underline">{{originAmount | localeCurrency}}</span>
    <span class="sale-amount">-{{project?.saleAmount}}%</span>
  </p>
</div>
