import * as moment from 'moment';
import {IRevenueHistory} from "@app/interfaces/revenue.interface";

export class RevenueModel {
  song: string;
  artist: string;
  date: any;
  rights: string;
  revenue: number;
  share: number;
  advance: {
    left: number;
    number: string;
  };
  files: {
    year: string,
    month: string,
    name: string,
    report: string
    id?: any
  }[];
  statements: {
    year: string,
    month: string,
    id?: any
  }[];
  takenDown: boolean;
  projectTitle: string;
  isOpen?: boolean;
  artistName: string;
  showTooltip?: boolean;
  isAlbum: boolean;
  revenueHistory: IRevenueHistory[];
  slug: string;
  leftShare: number;
  cardArtistTitle: string;
  albumSongName: string;
  isTax: boolean;
  constructor() {

  }

  setFromApiModel(apiModel: any): this {
    this.share = apiModel.share;
    this.leftShare = apiModel.soldShare ? apiModel.share - apiModel.soldShare : 0;
    this.revenue = apiModel.revenue;
    this.song = apiModel?.song?.name;
    this.isTax = apiModel.isTax;
    this.cardArtistTitle = apiModel.cardArtistTitle;
    this.isAlbum = apiModel.isAlbum;
    this.artistName = apiModel.artistName;
    this.albumSongName = apiModel.songName;
    this.slug = apiModel.slug;
    if (apiModel?.revenueHistory?.length) {
      this.revenueHistory = apiModel.revenueHistory;
    }

    this.artist = apiModel.artistName;
    this.projectTitle = apiModel.projectTitle;
    this.date = apiModel.date ? moment(apiModel.date, 'YYYY/MM/DD') : null;
    this.advance = apiModel.advance;
    this.takenDown = apiModel.takenDown;
    const rights = [];
    if (apiModel.royaltiesFromMaster) {
      rights.push('Master');
    }
    if (apiModel.royaltiesFromPublishingRights) {
      rights.push('Publishing');
    }

    this.rights = rights.join(' & ') + ' Rights';
    return this;
  }
}
