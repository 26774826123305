<div class="project-image" [style.backgroundImage]="'url(' + project?.backgroundImage + ')'" (click)="togglePlayer()">
    <i class="fas fa-stop" *ngIf="state?.playing"></i>
    <!-- <i class="fas fa-play" *ngIf="!state?.playing"></i> -->
  </div>
  
  <div class="mobile">
    <div class="name">
      <span class="artist">{{project?.artist?.name || project?.artist }} -
      </span>
      <span *ngIf="project?.isAlbum; else songName">{{project?.projectTitle }}</span>
      <ng-template #songName><span>{{project?.song?.name || project.song}}</span></ng-template>
    </div>
    <div class="progress-wrapper" *ngIf="!state?.error">
      <div class="played">{{state?.readableCurrentTime}}</div>
      <progress class="progress" (click)="videoTiming($event)" min="0" value="{{state?.progress}}" max="100">{{state?.progress}}
        <!-- <div class="inner" [style.width.%]="state?.progress"></div> -->
      </progress>
      <div class="total">{{state?.readableDuration}}</div>
    </div>
    <a *ngIf="enableButton" class="button share-button mobile yellow"
    href="https://www.globalrockstar.com/projects/{{project?.slug}}"
    (click)="openSupplierProfile(project, $event)" 
    [class.headliner]="project?.keepItAll && !project?.isCollectible && !project?.legacyHit?.isLegacyHit"
    [class.newcomer]="!project?.keepItAll && !project?.isCollectible && !project?.legacyHit?.isLegacyHit"
    [class.legacyHit]="project?.legacyHit?.isLegacyHit && !project?.isCollectible"
    [class.collectible]="project?.isCollectible">
      <span *ngIf="!project?.isCollectible && !project.isSold">Get your share</span>
      <span *ngIf="project?.isCollectible && !project.finished">Bid Now</span>
      <span *ngIf="project?.isSold || project.finished || project.releaseCreated">View Details</span>
    </a>
    <!-- <a class="button share-button mobile yellow" (click)="wouldInvestF('wouldInvest')"
       [class.disabled]="wouldInvestActive"
       *ngIf="project?.status ==='upcoming' && project?.isUpcoming || project?.activeUpcoming">
      I would invest
    </a> -->
  </div>
  <div class="volume">
    <div class="muted"(click)="muteSound()">
    <i *ngIf="!video.muted" class="fas fa-volume-up"></i>
    <i *ngIf="video.muted" class="fas fa-volume-mute"></i>
  </div>
  <progress class="volume-level" value="{{state.volume}}" max="100" min="0" (click)="updateVolume($event)">
</progress>
  </div>
  <div class="desktop-butt">
  <a *ngIf="enableButton" class="button share-button desktop yellow"
  href="https://www.globalrockstar.com/projects/{{project?.slug}}"
  (click)="openSupplierProfile(project, $event)"  
  [class.headliner]="project?.keepItAll && !project?.isCollectible && !project?.legacyHit?.isLegacyHit"
  [class.newcomer]="!project?.keepItAll && !project?.isCollectible && !project?.legacyHit?.isLegacyHit"
  [class.legacyHit]="project?.legacyHit?.isLegacyHit && !project?.isCollectible"
  [class.collectible]="project?.isCollectible">
    <span *ngIf="!project?.isCollectible && !project.isSold">Get your share</span>
    <span *ngIf="project?.isCollectible && !project.finished">Bid Now</span>
    <span *ngIf="project?.isSold || project.finished || project.releaseCreated">View Details</span>

  </a>
  </div>
  <!-- <button class="button share-button desktop yellow" (click)="wouldInvestF('wouldInvest')"
          [class.disabled]="wouldInvestActive || wouldInvestActiveChange"
          [disabled]="wouldInvestActive || wouldInvestActiveChange"
          *ngIf="((project?.status ==='upcoming' && project?.isUpcoming) || project?.activeUpcoming) && user">
    I would invest
  </button> -->
