<div class="card-outer" (click)="openSupplierProfile(item, $event, true)">
  <div class="card__content">
    <div class="card__image news__image">
      <img *ngIf="item?.image" src="{{item?.image}}">
      <img *ngIf="!item?.image" src="./assets/images/fallback.png">
    </div>
    <div class="card__inner">
      <div class="card__content-inner">
        <div class="card__date-box">
          <span class="lnr lnr-calendar-full"></span>
          <span class="card__date" *ngIf="item?.publishedAt">{{item?.publishedAt | momentDate}}</span>
        </div>
        <h2 class="card__title item-content-title-news" [title]="item?.title"
        >{{item?.title}}</h2>
        <div class="news-content" [class.player]="item?.spotifySongLink">
          <div class="card__description gray-text" [innerHTML]="item?.newsDescription"></div>
        </div>
        <div>
          <iframe *ngIf="item?.spotifySongLink" [src]="item?.spotifySongLink | safeHtmlUrl" width="100%"
                  height="80"
                  frameborder="0" loading="lazy" allowtransparency="true" allow="encrypted-media"></iframe>
        </div>
      </div>
    </div>
  </div>
  <div class="card__footer">
      <div class="social-view text-uppercase" *ngIf="item?.link?.url">
        <a href="{{item?.link?.url}}" target="_blank"
           *ngIf="item?.link?.text && item?.link?.url">{{item?.link?.text}}</a>
      </div>
      <div class="social-view text-uppercase" *ngIf="item?.spotifyArtistUrl">
        <iframe [src]="item?.spotifyArtistUrl | safeHtmlUrl" width="300" height="56" scrolling="no"
                frameborder="0"
                style="border:none; overflow:hidden;" allowtransparency="true"></iframe>
      </div>
      <div class="social-wrapper action-footer-item">
        <div *ngIf="item?.social?.twitter" class="followers-container">
          <a href="{{item?.social?.twitter}}" target="_blank">
                  <span class="social twitter">
                     <span class="circle"></span>
                      <i class="fab fa-twitter"></i>
                  </span>
          </a>
        </div>
        <div class="followers-container" *ngIf="item?.social?.instagram">
          <a href="{{item?.social?.instagram}}" target="_blank">
                  <span class="social instagram">
                     <span class="circle"></span>
                     <i class="fab fa-instagram"></i>
                  </span>
          </a>
        </div>
        <div *ngIf="item?.social?.youtube" class="followers-container">
          <a href="{{item?.social?.youtube}}" target="_blank">
                  <span class="social youtube">
                     <span class="circle"></span>
                     <i class="fab fa-youtube"></i>
                  </span>
          </a>
        </div>
        <div *ngIf="item?.social?.facebook" class="followers-container">
          <a href="{{item?.social?.facebook}}" target="_blank">
                  <span class="social facebook">
                     <span class="circle"></span>
                    <i class="fab fa-facebook-f"></i>
                  </span>
          </a>
        </div>
        <div *ngIf="item?.social?.spotify" class="followers-container">
          <a href="{{item?.social?.spotify}}" target="_blank">
                   <span class="social spotify">
                     <i class="fab fa-spotify"></i>
                  </span>
          </a>
        </div>
      </div>
  </div>
</div>
