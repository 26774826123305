import {ROYALTY_PREVIEW} from './constants';
import {timeType} from './interfaces';
import {Collection} from '@app/types';

export function jsonTo(list) {
  return JSON.parse(JSON.stringify(list));
}

export function encodeStringUri(str) {
  return encodeURIComponent(str);
}

export function decodeStringUri(str) {
  return decodeURI(str);
}

export function getTimeArrayNumber(type: timeType): string[] {
  const num = {
    hours: 24,
    minutes: 60
  };

  return Array.from(Array(num[type]).keys()).map(item => {
    if (item < 10) {
      return '0' + item;
    }
    return item.toString();
  });

}

export class Filters {
  offset = 1;
  limit = 6;
  page = 1;

  constructor() {
  }

  setLimit(limit: number = 9): Filters {
    this.limit = limit;
    return this;
  }

  setOffset(offset: number = 1): Filters {
    this.offset = offset;
    return this;
  }

  setPage(page: number = 1): Filters {
    this.page = page;
    return this;
  }

  increment(customOffset?: number): Filters {
    const offset: number = customOffset || this.limit;

    this.offset = this.offset + offset;
    this.page++
    return this;
  }

  getPaging(): any {
    return {
      offset: this.offset,
      limit: this.limit,
      page: this.page
    };
  }
}

function getRoyaltyAgreementDIY(isPrivate, isMaster) {
  if (isPrivate) {
    return isMaster ? ROYALTY_PREVIEW['diy'].masterPrivate : ROYALTY_PREVIEW['diy'].masterPublishPrivate;
  }
  return isMaster ? ROYALTY_PREVIEW['diy'].masterCompany : ROYALTY_PREVIEW['diy'].masterPublishCompany;
}

function getRoyaltyAgreementGRS(isPrivate, project, language, user) {
  const {royaltiesFromPublishingRights, isAlbum} = project;

  if (royaltiesFromPublishingRights) {
    if (isAlbum) {
      return (isPrivate || !user) ?
        ROYALTY_PREVIEW['headliner'][language].masterPublishPrivateAlbum : ROYALTY_PREVIEW['headliner'][language].masterPublishCompanyAlbum;
    } else {
      return (isPrivate || !user)
        ? ROYALTY_PREVIEW['headliner'][language].masterPublishPrivate : ROYALTY_PREVIEW['headliner'][language].masterPublishCompany;
    }
  }

  if (isAlbum) {
    return (isPrivate || !user) ? ROYALTY_PREVIEW['headliner'][language].masterPrivateAlbum : ROYALTY_PREVIEW['headliner'][language].masterCompanyAlbum;
  } else {
    return (isPrivate || !user)
      ? ROYALTY_PREVIEW['headliner'][language].masterPrivate : ROYALTY_PREVIEW['headliner'][language].masterCompany;
  }

}

export function getRoyalties(project, user) {

  if (!project || !project.royaltiesFromMaster) {
    return null;
  }


 // const HEADLINER = project && project.keepItAll;
  const DIY = project && project.artistCategory === 'diy';
  const LANGUAGE = user && user.language ? user.language : 'en';
 // const TYPEPROJECT = HEADLINER ? 'headliner' : 'newcomer';
  const ISPRIVATE = !!(user && user.isPrivate);
  let royalty;

  if (DIY) {
    royalty = getRoyaltyAgreementDIY(ISPRIVATE, project.royaltiesFromMaster);
  } else {
    royalty = getRoyaltyAgreementGRS(ISPRIVATE, project, LANGUAGE, user)
  }

  return royalty;
}

export function _parseCount(amount) {
  if (amount > 1000000) {
    // tslint:disable-next-line: radix
    amount = parseInt((amount / 100000).toString()) / 10;
    amount = amount + 'M';
    return amount;
  } else {
    if (amount > 1000) {
      // tslint:disable-next-line: radix
      amount = parseInt((amount / 100).toString()) / 10;
      amount = amount + 'K';
      return amount;
    } else {
      return amount;
    }
  }
}

export function _getSpotifyFollowers(project) {
  project.spotifyFollowers = _parseCount(project.spotifyFollowers);
  if (project.spotifyFollowers) {
    project.socialrow = true;
  }
}

export function _getTwitterFollowers(project) {
  project.twitterFollowers = _parseCount(project.twitterFollowers);
  if (project.twitterFollowers) {
    project.socialrow = true;
  }
}

export function isValidIBANNumber(input: string): { ibanValid: boolean } {
  const CODE_LENGTHS = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26,
    AL: 28,
  };
  const iban = String(input)
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, '');
  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);

  let digits: string;
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return {ibanValid: false};
  }

  const Replacers: any = (letter: any) => letter.charCodeAt(0) - 55;
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, Replacers);
  return mod97(digits) === 1
    ? {ibanValid: true}
    : {ibanValid: false};
}

export function mod97(digital: number | string): number | string {
  digital = digital.toString();
  let checksum: number | string = digital.slice(0, 2),
    fragment;
  for (let offset = 2; offset < digital.length; offset += 7) {
    fragment = String(checksum) + digital.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
}

export function trimFormDataValues(formData: Collection<unknown>): Collection<any> {
  Object.keys(formData).forEach((item) => {
    if (typeof formData[item] == "string") {
      formData[item] = (formData[item] as string).trim();
    }
  })

  return formData;
}

export function getCookie(cname: string, document: Document): string {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function extractFileExtension(fileName: string): string | undefined {
  const regexp: RegExp = /(?:\.([^.]+))?$/;

  return regexp.exec(fileName)[1];
}
