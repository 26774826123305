<div class="wrapper">
  <div class="wrapper-info">
    <div class="project-image-wrapper">
      <div class="project-image" *ngIf="song?.soundtrack" (click)="togglePlayer()">
        <i class="fas fa-pause" *ngIf="state?.playing"></i>
        <i class="fas fa-play" *ngIf="!state?.playing"></i>
      </div>
    </div>
    <div class="song-time">
      <div class="name">
        <span class="artist">{{project?.artist?.name}} - </span><span class="song-name">{{'&nbsp;'+song?.name || project?.description}}</span>
      </div>
      <div class="total">{{state?.readableDuration}}</div>
    </div>
  </div>
  <div class="progress">
    <div class="inner" [style.width.%]="state?.progress"></div>
  </div>
</div>
