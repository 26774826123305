<div class="project-image" [style.backgroundImage]="'url(' + project?.image + ')'" (click)="togglePlayer()">
  <i class="fas fa-stop" *ngIf="state?.playing"></i>
  <i class="fas fa-play" *ngIf="!state?.playing"></i>
</div>

<div>
  <div class="name">
    <span class="artist">{{project?.artist?.name}} -
    </span>{{project.isAlbum ? project.projectTitle : (project?.song?.name || project?.description)}}
  </div>
  <div class="progress-wrapper" *ngIf="!state?.error">
    <div class="played">{{state?.readableCurrentTime}}</div>
    <!-- <div class="progress">
      <div class="inner" [style.width.%]="state?.progress" (click)="videoTiming($event)" min="0" value="{{state?.progress}}" max="100"></div>
    </div> -->
    <progress class="progress" (click)="videoTiming($event)" min="0" value="{{state?.progress}}" max="100">{{state?.progress}}
      <!-- <div class="inner" [style.width.%]="state?.progress"></div> -->
    </progress>
    <div class="total">{{state?.readableDuration}}</div>
  </div>
  <a class="button share-button mobile yellow"
     (click)="goToCalculationPage(project?.isFunded || project?.daysLeft <= 0 || project?.isFailed)"
     *ngIf="project?.slug && !project?.isSold && !project.isUpcoming && !project?.isFunded && !project?.isFailed">
    Get your share
  </a>
  <a class="button share-button mobile yellow" (click)="wouldInvestF('wouldInvest')"
     [class.disabled]="wouldInvestActive"
     *ngIf="project?.status ==='upcoming' && project?.isUpcoming || project?.activeUpcoming">
    I would invest
  </a>
</div>
<div class="volume">
  <div class="muted"(click)="muteSound()">
  <i *ngIf="!state.muted" class="fas fa-volume-up"></i>
  <i *ngIf="state.muted" class="fas fa-volume-mute"></i>
</div>
<progress class="volume-level" value="{{state.volume}}" max="100" min="0" (click)="updateVolume($event)">
</progress>
</div>

<a class="button share-button desktop yellow"
   (click)="goToCalculationPage(project?.isFunded || project?.daysLeft <= 0 || project?.isFailed)"
   *ngIf="project?.slug && !project.isUpcoming && !project?.isFunded && !project?.isFailed && project?.daysLeft > 0">
  Get your share
</a>
<button class="button share-button desktop yellow" (click)="wouldInvestF('wouldInvest')"
        [class.disabled]="wouldInvestActive || wouldInvestActiveChange"
        [disabled]="wouldInvestActive || wouldInvestActiveChange"
        *ngIf="((project?.status ==='upcoming' && project?.isUpcoming) || project?.activeUpcoming) && user">
  I would invest
</button>
